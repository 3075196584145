import './pendingOrder.css'
import * as React from "react";
import PendingOrderProduct from "./PendingOrderProduct";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";

const PendingOrder = ({ order, closeModal, cartId, email, date }) => {

    const mySystem = useMySystem()
    const auth = useAuthProvider()
    const token = auth.getAdminToken()

    const getCheapestOption = (product) => {
        const cheapestOption = {
            cartId: cartId,
            name: product.name,
            price: product.lowestPrice,
            image: '',
            supermarket: '',
            link: '',
        }
        if (product.jumboPrice === product.lowestPrice) {
            cheapestOption.image = product.jumboImage
            cheapestOption.supermarket = 'Jumbo'
            cheapestOption.link = product.jumboLink
        } else if (product.carrefourPrice === product.lowestPrice) {
            cheapestOption.image = product.carrefourImage
            cheapestOption.supermarket = 'Carrefour'
            cheapestOption.link = product.carrefourLink
        } else if (product.cotoPrice === product.lowestPrice) {
            cheapestOption.image = product.cotoImage
            cheapestOption.supermarket = 'Coto'
            cheapestOption.link = product.cotoLink
        }

        return cheapestOption;
    }

    const confirmBought = () => {
        mySystem.setBoughtOrder(token, {cartId: cartId})
        closeModal()
    }

    const confirmDelivered = () => {
        mySystem.setDeliveredOrder(token, {cartId: cartId})
        closeModal()
    }

    return (
        <div className="pending-order-modal">
            <div className="orders-container">
                <div className="modal-header">
                    <h2>Pedido {date}</h2>
                    <button className="close-modal" onClick={closeModal}>&times;</button>
                </div>
                <div className="products-container">
                {
                    order.cartItems.map((product) => {
                        return (
                            <PendingOrderProduct product={getCheapestOption(product)}/>
                        )
                    })
                }
                </div>
                {
                    order.state === "ordered" &&
                        <button className="confirm-bought-button" onClick={confirmBought}>Confirmar compra</button>
                }
                {
                    order.state === "bought" &&
                        <button className="confirm-bought-button" onClick={confirmDelivered}>Confirmar envío</button>
                }
                {
                    order.state === "delivered" &&
                        <div style={{ textAlign: 'center', fontSize: '1.2vw', fontWeight: '600', marginTop: '2vw' }}>Pedido enviado</div>
                }
            </div>
        </div>
    )
}

export default PendingOrder;