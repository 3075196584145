import {AddButton} from "./AddButton";
import * as React from "react";
import {useEffect, useState} from "react";
import styled from "styled-components";


const ProductCard = (props) => {

    const product = props.product
    const setSelectedProduct = props.setSelectedProduct
    const [pricePerUnity, setPricePerUnity] = useState(0)
    const [unityOfMeasure, setUnityOfMeasure] = useState('')

    const [clickMessage, setClickMessage] = useState(false);
    const [clickMessagePosition, setClickMessagePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        // setUnityOfMeasure(product.unityOfMeasure === "Gr" ? 'kg' : 'lt')
        setUnityOfMeasure(product.unityOfMeasure)
        calculatePricePerUnity()
    }, [props.aux])

    const calculatePricePerUnity = () => {
        setPricePerUnity(product.lowestPrice / product.quantity)
    }

    const handleMouseEnter = (event) => {
        const rect = event.target.getBoundingClientRect();
        setClickMessagePosition({ x: event.clientX - rect.left, y: event.clientY - rect.top });
        setClickMessage(true);
    };

    const handleMouseLeave = () => {
        setClickMessage(false);
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }

    return (
        <StyledProductCard>
            <li key={product} className="product-card">
                <div className="product-img-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}  onClick={() => setSelectedProduct(product)}>
                    {/*<img src={process.env.PUBLIC_URL + '/uploads/' + product.category + '/' + product.name + '.png'} alt={product.name}/>*/}
                    {product.jumboPrice !== -1 ? (
                        <img src={product.jumboImage} alt={product.name} onClick={() => setSelectedProduct(product)}/>
                    ) : product.carrefourPrice !== -1 ? (
                        <img src={product.carrefourImage} alt={product.name} onClick={() => setSelectedProduct(product)}/>
                    ) : product.cotoPrice !== -1 && (
                        <img src={product.cotoImage} alt={product.name} onClick={() => setSelectedProduct(product)}/>
                    )
                    }
                </div>
                {clickMessage && (
                    <div className="click-for-info" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => setSelectedProduct(product)}>
                        Ver info
                    </div>
                )}
                <div className="product-name" onClick={() => setSelectedProduct(product)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    {product.name}
                </div>
                <div className="product-price" onClick={() => setSelectedProduct(product)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    {formatNumber(product.lowestPrice)}
                    {parseInt(100 - (product.lowestPrice*100)/product.highestPrice) > 0 &&
                        <div className="product-discount">-{parseInt(100 - (product.lowestPrice*100)/product.highestPrice)}%</div>
                    }
                    {/*<div className="product-discount">-{formatNumber(product.highestPrice - product.lowestPrice)}</div>*/}
                </div>
                <div className="price-per-unit">
                    ({formatNumber(pricePerUnity)} x {unityOfMeasure})
                </div>
                <AddButton productId={product.ean} sortBy={props.sortBy} auxCartAmount={props.auxCartAmount} setAuxCartAmount={props.setAuxCartAmount} setCartItems={props.setCartItems} setRegisterModal={props.setRegisterModal}/>
            </li>
        </StyledProductCard>
    )
}

export default ProductCard


const StyledProductCard = styled.div`
  
  .product-card{
    border-radius: 1vw;
    box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.11);
    text-align: center;
    font-size: medium;
    height: 23.5vw;
    width: 14vw;
    /*margin-left: 20%;*/
    /*margin-top: -5.5vw;*/
    list-style: none;
  }

  .click-for-info{
    margin: -7vw 0 0 3.5vw;
    width: 6%;
    position: absolute;
    background-color: rgba(68, 68, 68, 0.76);
    /*box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
    padding: 0.5vw;
    border-radius: 0.5vw;
    color: rgba(250, 248, 248, 0.92);
    cursor: pointer;
  }

  .product-card img{
    cursor: pointer;
    width: 65%;
    height: 39%;
    margin-top: 7%;
    //mix-blend-mode: screen;
    user-select: none; /*hace que no se pueda seleccionar el elemento (que se ponga en azul)*/
  }

  .product-name {
    cursor: pointer;
    /*height: 2vw;*/
    width: 90%;
    padding: 0.1vw 0.8vw;
    margin: 5% 0.1%;
    font-size: 1vw;
    height: 3.7vw;
    overflow: hidden;
    /*display: flex;*/
    /*align-items: center;*/
  }
  
  .product-name:hover {
    /*overflow: visible;*/
    overflow: auto;
  }
  
  .product-name::-webkit-scrollbar {
    /*overflow: visible;*/
    width: 0.5vw;
    margin-left: -54%;
  }
  
  .product-price {
    margin-top: 0.5vw;
    font-weight: bold;
    font-size: 1.2vw;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5vw;
  }
  
  .product-price p {
    font-size: 1vw;
    font-weight: 400;
  }
  
  .product-discount {
    color: red;
    font-size: 1vw;
  }
  
  .price-per-unit {
    margin-top: 0.5vw;
    font-size: 0.8vw;
    height: 1vw;
  }

  @media(max-device-width: 700px){
    .product-card{
      border-radius: 4vw;
      box-shadow: 0 0 0.5vw 0.1vw rgba(0,0,0,0.2);
      border: 1px solid rgba(0,0,0,0.1);
      text-align: center;
      font-size: medium;
      height: 80vw;
      width: 44vw;
      /*margin-top: -5.5vw;*/
      list-style: none;
    }

    .click-for-info{
      display: none;
    }

    .product-card img{
      cursor: pointer;
      width: 65%;
      height: 39%;
      margin-top: 7%;
      //mix-blend-mode: screen;
      pointer-events: none; /*hace que no se pueda seleccionar el elemento (que se ponga en azul)*/
    }

    .product-name {
      cursor: pointer;
      /*height: 2vw;*/
      width: 92%;
      padding: 0 4%;
      margin: 5% 0.1%;
      font-size: 3.5vw;
      height: 15vw;
      overflow: hidden;
      text-align: center;
      /*display: flex;*/
      /*align-items: center;*/
    }

    .product-name:hover {
      /*overflow: visible;*/
      overflow: auto;
    }

    .product-name::-webkit-scrollbar {
      /*overflow: visible;*/
      width: 2vw;
      margin-left: -54%;
    }

    .product-price {
      margin-top: 0.5vw;
      font-weight: bold;
      font-size: 4vw;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5vw;
    }

    .product-price p {
      font-size: 4vw;
      font-weight: 400;
    }

    .product-discount {
      color: red;
      font-size: 3vw;
    }

    .price-per-unit {
      margin-top: 4%;
      font-size: 3vw;
      height: 3vw;
    }
  }
  
`