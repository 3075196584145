import * as React from 'react'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router";
import './productStyle.css'
import Navbar from "../components/Navbar";
import IconoCarrito from "../components/IconoCarrito";
import {AddButton} from "../components/AddButton";
import Popup from "../components/Popup";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";
import GoBackButton from "../components/GoBackButton";
import Loader from "../components/Loader";
import ProductCard from "../components/ProductCard";
import {AiFillInfoCircle} from "react-icons/ai";
import {BiSearch} from "react-icons/bi";
import {BsFillTrash3Fill} from "react-icons/bs";
import {TbArrowsDownUp} from "react-icons/tb";
import {VscSettings} from "react-icons/vsc";
import logo from './images/Logo White 1.png';
import ProgressBar from "../components/ProgressBar";
import Modal from "../components/Modal";
import {Link} from "react-router-dom";

export const ProductPage = () => {

    const location = useLocation();
    const [selectedName, setSelectedName] = useState('')
    const [products, setProducts] = useState([])
    const mySystem = useMySystem()
    const auth = useAuthProvider()
    const [selectedProduct, setSelectedProduct] = useState();
    const [errorMsg, setErrorMsg] = useState(undefined)
    const token = auth.getToken();
    const [cartItems, setCartItems] = useState([]);

    const [auxSearchProducts, setAuxSearchProducts] = useState([]);
    const [auxFiltersProducts, setAuxFiltersProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [sortBy, setSortBy] = useState('order-most-discount');
    const [auxSort, setAuxSort] = useState(true);
    const [aux, setAux] = useState(true);

    const [brandOptions, setBrandOptions] = useState([]);
    const [contentOptions, setContentOptions] = useState([]);
    const [subcategoryOptions, setSubcategoryOptions] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        brand: [],
        content: [],
        subcategory: [],
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [auxCartAmount, setAuxCartAmount] = useState(false);
    const [amountProductsShown, setAmountProductsShown] = useState(16);
    const [isLoading, setIsLoading] = useState(true);
    const [showInfo, setShowInfo] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);

    useEffect(() => {
        const pathname = location.pathname.slice(9)
        setSelectedName(pathname)
        mySystem.listProducts(pathname, token, setIsLoading, (products) => setProducts(products))
        mySystem.showCart(setIsLoading, token, (cartItems) => setCartItems(cartItems));
    }, [])

    useEffect(() => {
        fillProducts(setAuxSearchProducts)
        fillProducts(setAuxFiltersProducts)
        fillProducts(setFilteredProducts)
        console.log(products.length)
    }, [products])

    const showMoreProducts = () => {
        // Aumenta la cantidad de productos a mostrar en 20 cada vez que se hace clic en el botón
        setAmountProductsShown(amountProductsShown + 16);
    };

    const handleClick = (link) => {
        window.open(link, '_blank');
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }

    const formatUnit = (unit) => {
        if (unit === 'ML') {
            return 'ml';
        } else if (unit === 'Gr') {
            return 'gr';
        } else {
            return unit
        }
    }

    const handleSortChange = (event) => {
        const newSortBy = event.target.value;
        setSortBy(newSortBy);
        sortProducts(newSortBy);
        setAux(!aux);
    };

    const sortProducts = (sortBy) => {
        // const sortedProducts = [...filteredProducts];

        filteredProducts.sort((a, b) => {
            if (sortBy === 'order-price-asc') {
                return a.lowestPrice - b.lowestPrice;
            } else if (sortBy === 'order-price-desc') {
                return b.lowestPrice - a.lowestPrice;
            } else if (sortBy === 'order-name-asc') {
                return a.name.localeCompare(b.name);
            } else if (sortBy === 'order-name-desc') {
                return b.name.localeCompare(a.name);
            } else if (sortBy === 'order-most-discount') {
                // return (b.highestPrice - b.lowestPrice) - (a.highestPrice - a.lowestPrice);
                return (100 - (b.lowestPrice*100)/b.highestPrice) - (100 - (a.lowestPrice*100)/a.highestPrice);
            } else if (sortBy === 'order-best-unity-price') {
                return ((1000 / a.quantity) * a.lowestPrice) - ((1000 / b.quantity) * b.lowestPrice);
            }
        });
        // setFilteredProducts(sortedProducts);
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        filterProducts(newSearchTerm);
        setAux(!aux);
    };

    const filterProducts = (searchTerm) => {
        const filtered = auxSearchProducts.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProducts(filtered);
    };

    const updateFilterOptions = () => {
        // Obtiene todas las marcas únicas de los productos.
        const uniqueBrands = [...new Set(auxFiltersProducts.map((product) => product.brand))].sort();
        setBrandOptions(uniqueBrands);

        // Obtiene todos los contenidos únicos de los productos.
        const uniqueContents = [...new Set(auxFiltersProducts.map((product) => Number(product.quantity)))].sort((a, b) => a - b);
        setContentOptions(uniqueContents);

        // Obtiene todas las subcategorías únicas de los productos.
        const uniqueSubcategories = [...new Set(auxFiltersProducts.map((product) => product.subcategory))].sort();
        setSubcategoryOptions(uniqueSubcategories);
    };

    useEffect(() => {
        // Cuando cambie la lista de productos, actualiza las opciones de filtro.
        updateFilterOptions();
    }, [auxFiltersProducts]);

    const handleFilterChange = (category, value) => {
        // Copia el estado actual de los filtros seleccionados.
        const updatedFilters = { ...selectedFilters };
        // Si el valor ya está seleccionado, quítalo; de lo contrario, agrégalo.
        if (updatedFilters[category].includes(value)) {
            updatedFilters[category] = updatedFilters[category].filter((item) => item !== value);
        } else {
            updatedFilters[category].push(value);
        }

        // Actualiza el estado de los filtros seleccionados.
        setSelectedFilters(updatedFilters);

        // Llama a la función applyFilters para aplicar los filtros.
        applyFilters(selectedFilters);
    };

    const applyFilters = (selectedFilters) => {
        const filtered = auxSearchProducts.filter((product) => {
            // Verifica si el producto cumple con los filtros seleccionados.
            return (
                selectedFilters.brand.length === 0 ||
                selectedFilters.brand.includes(product.brand)
            ) && (
                selectedFilters.content.length === 0 ||
                selectedFilters.content.includes(product.quantity)
            ) && (
                selectedFilters.subcategory.length === 0 ||
                selectedFilters.subcategory.includes(product.subcategory)
            );
        });
        setAux(!aux)
        setFilteredProducts(filtered);
    };

    const handleEmptyFilters = () => {
        setSelectedFilters({ brand: [], content: [], subcategory: []})
        fillProducts(setFilteredProducts)
        setAux(!aux)
    }

    const fillProducts = (setFunction) => {
        setFunction(products)

        const amount = 1;

        if (amount === 1) {
            setFunction(products.filter(product =>
                (product.jumboPrice > 0  || product.carrefourPrice > 0 || product.cotoPrice > 0)
            ));
        } else if (amount === 2) {
            setFunction(products.filter(
                (product =>
                        (product.jumboPrice > 0 && product.carrefourPrice > 0) ||
                        (product.carrefourPrice > 0 && product.cotoPrice > 0) ||
                        (product.jumboPrice > 0 && product.cotoPrice > 0)
                )
            ));
        } else if (amount === 3) {
            setFunction(products.filter(product => product.jumboPrice > 0 && product.carrefourPrice > 0 && product.cotoPrice > 0));
        }
    }

    const handleInfoGeneralIn = () => {
        setShowInfo(true);
    };

    const handleInfoGeneralOut = () => {
        setShowInfo(false);
    };

    const handleInfoModalIn = () => {
        setShowInfoModal(true);
    };

    const handleInfoModalOut = () => {
        setShowInfoModal(false);
    };

    const handleCloseRegisterModal = () => {
        setRegisterModal(false);
    }

    const formatName = (name) => {
        if (name === "isotonicas") {
            return "Isotónicas";
        }
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    return(
        <div className="container">
            <Navbar aux={auxCartAmount}/>
            <div className="carrito-web">
                <IconoCarrito updateCartAmount={auxCartAmount} cartItems={cartItems}/>
            </div>
            {/*<div className="progressbar">*/}
            {/*    <ProgressBar/>*/}
            {/*</div>*/}
            <div className="header-products">
                <div className="go-back">
                    <GoBackButton />
                </div>
                <div className="title-container"  style={{ flex: '95' }} >
                    <h1 className="title-product">{selectedName.toUpperCase()}</h1>
                </div>
                <AiFillInfoCircle
                    color={'rgb(200, 200, 210)'}
                    onMouseOver={handleInfoGeneralIn}
                    onMouseOut={handleInfoGeneralOut}
                    size={'1.3vw'}
                    className="product-info-icon"
                />
                {showInfo &&
                    <div className="info-title">
                        Se muestra el precio más barato de cada producto. Para ver todos los precios hay que hacer click sobre la imagen del producto.
                    </div>
                }
            </div>
            <div className="filters">
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Buscar productos..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    <BiSearch className="search-products-icon" color={'#bbb'} size={'1vw'}/>
                </div>
                <div className="filters-order">
                    <button className="filters-mobile">
                        <div className="filter-icon">
                            <VscSettings size={'5vw'}/>
                        </div>
                        Filtrar
                    </button>
                    <div className="order-by">
                        <a>Ordenar por:</a>
                        <div className="order-icon">
                            <TbArrowsDownUp size={'4.5vw'}/>
                        </div>
                        <select className="order-selector" value={sortBy} onChange={handleSortChange}>
                            <option value="order-most-discount">Mayor descuento</option>
                            <option value="order-best-unity-price">Mejor precio por unidad</option>
                            <option value="order-price-asc">Precio: Menor a mayor</option>
                            <option value="order-price-desc">Precio: Mayor a menor</option>
                            <option value="order-name-asc">Nombre: A-Z</option>
                            <option value="order-name-desc">Nombre: Z-A</option>
                        </select>
                    </div>
                </div>
            </div>
            {
                isLoading ? (
                    <div className="product-loader">
                        <div className="loader-web">
                            <Loader width='8vw'/>
                        </div>
                        <div className="loader-mobile">
                            <Loader width='20vw'/>
                        </div>
                    </div>
                ) : (
                    <div className="container-margins">
                        <div className="products-list-container">
                            <ul className="producto">
                                {sortProducts(sortBy)}
                                {errorMsg && <div className="alert-product alert-danger" role="alert">{errorMsg}</div>}
                                {filteredProducts.slice(0, amountProductsShown).map(product =>
                                    <ProductCard product={product} setSelectedProduct={setSelectedProduct}
                                                 sortBy={sortBy} auxCartAmount={auxCartAmount}
                                                 setAuxCartAmount={setAuxCartAmount} aux={aux}
                                                 setCartItems={setCartItems}
                                                 setRegisterModal={setRegisterModal}
                                    />
                                )
                                }
                                {
                                    selectedProduct && (
                                        <Popup className="popup" product={selectedProduct}>
                                            <div className="popup-header">
                                                <div className="popup-name">{selectedProduct.name}</div>
                                                <button className="close-btn"
                                                        onClick={() => setSelectedProduct(undefined)}>&times;</button>
                                            </div>

                                            {showInfoModal &&
                                                <div className="info-product-modal">
                                                    Al hacer click sobre un precio se abre automáticamente el link del
                                                    producto en el supermercado seleccionado
                                                </div>
                                            }
                                            <div className="popup-product-container">
                                                <div className="image-container">
                                                    {selectedProduct.jumboPrice !== -1 ? (
                                                        <img src={selectedProduct.jumboImage} alt={selectedProduct.name}/>
                                                    ) : selectedProduct.carrefourPrice !== -1 ? (
                                                        <img src={selectedProduct.carrefourImage}
                                                             alt={selectedProduct.name}/>
                                                    ) : selectedProduct.cotoPrice !== -1 && (
                                                        <img src={selectedProduct.cotoImage} alt={selectedProduct.name}/>
                                                    )
                                                    }
                                                </div>
                                                <div className="product-info">
                                                    <div className="field">
                                                        <h3>Tipo: </h3>
                                                        <p>{selectedProduct.subcategory !== "subcategoria no encontrada" ? selectedProduct.subcategory : formatName(selectedName)}</p>
                                                    </div>
                                                    <div className="field">
                                                        <h3>Marca: </h3>
                                                        <p>{selectedProduct.brand}</p>
                                                    </div>
                                                    <div className="field">
                                                        <h3>Contenido: </h3>
                                                        <p>{selectedProduct.quantity} {formatUnit(selectedProduct.unityOfMeasure)}</p>
                                                    </div>
                                                    <div className="field-smarket-container">
                                                        <div className="field-smarket">
                                                            <img src={logo} alt=""/>
                                                            <p>{formatNumber(selectedProduct.lowestPrice)}</p>
                                                        </div>
                                                        {parseInt(100 - (selectedProduct.lowestPrice * 100) / selectedProduct.highestPrice) > 0 &&
                                                            <div
                                                                className="product-discount">-{parseInt(100 - (selectedProduct.lowestPrice * 100) / selectedProduct.highestPrice)}%</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-icon">
                                                <AiFillInfoCircle
                                                    color={'rgb(200, 200, 210)'}
                                                    onMouseOver={handleInfoModalIn}
                                                    onMouseOut={handleInfoModalOut}
                                                    style={{
                                                        marginLeft: '93%',
                                                        marginTop: '0',
                                                        width: '1.75vw',
                                                        height: '1.75vw'
                                                    }}
                                                    size={'1.3vw'}
                                                />
                                            </div>
                                            <div className="popup-body">
                                                {selectedProduct.jumboPrice > 0 && (
                                                    <div className="supermarket-product"
                                                         onClick={() => handleClick(selectedProduct.jumboLink)}
                                                         style={{cursor: "pointer"}}>
                                                        <img src={selectedProduct.jumboImage}
                                                             alt={selectedProduct.jumboName}/>
                                                        <div className="names-container">
                                                            <div
                                                                className="super-name">Jumbo: {formatNumber(selectedProduct.jumboPrice)}</div>
                                                            <div className="price-name">{selectedProduct.jumboName}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedProduct.carrefourPrice > 0 && (
                                                    <div className="supermarket-product"
                                                         onClick={() => handleClick(selectedProduct.carrefourLink)}
                                                         style={{cursor: "pointer"}}>
                                                        <img src={selectedProduct.carrefourImage}
                                                             alt={selectedProduct.carrefourName}/>
                                                        <div className="names-container">
                                                            <div
                                                                className="super-name">Carrefour: {formatNumber(selectedProduct.carrefourPrice)}</div>
                                                            <div
                                                                className="price-name">{selectedProduct.carrefourName}</div>
                                                        </div>
                                                    </div>
                                                )}
                                                {selectedProduct.cotoPrice > 0 && (
                                                    <div className="supermarket-product"
                                                         onClick={() => handleClick(selectedProduct.cotoLink)}
                                                         style={{cursor: "pointer"}}>
                                                        <img src={selectedProduct.cotoImage}
                                                             alt={selectedProduct.cotoName}/>
                                                        <div className="names-container">
                                                            <div
                                                                className="super-name">Coto: {formatNumber(selectedProduct.cotoPrice)}</div>
                                                            <div className="price-name">{selectedProduct.cotoName}</div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </Popup>
                                    )
                                }
                            </ul>
                            <div className="product-filters">
                                <h3>
                                    Filtros
                                    <BsFillTrash3Fill className="empty-filters" onClick={handleEmptyFilters}/>
                                </h3>
                                {subcategoryOptions.length > 1 && (
                                    <div>
                                        <h4>Sub-categoría</h4>
                                        <div className="individual-filter">
                                            <div className="filter-content">
                                                {subcategoryOptions.map((option) => (
                                                    <label key={option}>
                                                        <input
                                                            type="checkbox"
                                                            value={option}
                                                            checked={selectedFilters.subcategory.includes(option)}
                                                            onChange={() => handleFilterChange('subcategory', option)}
                                                        />
                                                        {option}
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )
                                }
                                <h4>Marca</h4>
                                <div className="individual-filter">
                                    <div className="filter-content">
                                        {brandOptions.map((option) => (
                                            <label key={option}>
                                                <input
                                                    type="checkbox"
                                                    value={option}
                                                    checked={selectedFilters.brand.includes(option)}
                                                    onChange={() => handleFilterChange('brand', option)}
                                                />
                                                {option}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                <h4>Contenido</h4>
                                <div className="individual-filter">
                                    <div className="filter-content">
                                        {contentOptions.map((option) => (
                                            <label key={option}>
                                                <input
                                                    type="checkbox"
                                                    value={option}
                                                    checked={selectedFilters.content.includes(option)}
                                                    onChange={() => handleFilterChange('content', option)}
                                                />
                                                {option} {products[0].unityOfMeasure}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {amountProductsShown < filteredProducts.length && (
                            <button className="show-more-products-button" onClick={showMoreProducts}>Mostrar
                                más</button>
                        )}
                        {registerModal && (
                            <Modal isOpen={registerModal} onClose={handleCloseRegisterModal}>
                                <div className="register-modal">
                                    <p className="register-modal-info">
                                        Para poder agregar productos al carrito es necesario que te registres o inicies
                                        sesión.
                                    </p>
                                    <div className="register-modal-buttons">
                                        <label>
                                            <Link to="/login">
                                                <button type="button" className="register-modal-button">Iniciar Sesión
                                                </button>
                                            </Link>
                                        </label>
                                        <div
                                            style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                                            <hr/>
                                        </div>
                                        <label>
                                            <Link to="/register">
                                                <button type="button" className="register-modal-button">Registrarse
                                                </button>
                                            </Link>
                                        </label>
                                        <div style={{height: '2vw'}}/>
                                    </div>
                                </div>
                            </Modal>
                        )}
                    </div>
                )
            }

        </div>
    )
}
