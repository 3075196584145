import React, {useEffect} from "react";
import { useState } from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";
import {useNavigate} from "react-router";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {TbTrash} from "react-icons/tb";
import Loader from "./Loader";
import styled from "styled-components";

export const AddButton = (props) => {

    const mySystem = useMySystem()
    const [errorMsg, setErrorMsg] = useState(undefined)
    const productId = props.productId
    const [productButton, setproductButton] = useState("Agregar")
    const auth = useAuthProvider()
    const token = auth.getToken()
    const navigate = useNavigate()
    const [amount, setAmount] = useState(0)
    const setCartItems = props.setCartItems

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        mySystem.getAmount(token, setIsLoading, {productId: productId}, (amount) => setAmount(amount))
    }, [props.sortBy])

    function AddToCart(newAmount){
        AddProductToCart({
            productId: productId,
            amount: newAmount,
        })
        props.setAuxCartAmount(!props.auxCartAmount)
    }

    function RemoveFromCart(){
        AddProductToCart({
            productId: productId,
            amount: amount-1,
        })
        props.setAuxCartAmount(!props.auxCartAmount)
    }

    const AddProductToCart = (productIdAndAmount) => {
        if (token === undefined) {
            props.setRegisterModal(true)
        } else {
            mySystem.addProducts(
                token,
                productIdAndAmount,
                () => {
                    // update IconoCarrito
                    mySystem.showCart(setIsLoading, token, (cartItems) => setCartItems(cartItems));
                    setAmount(productIdAndAmount.amount)
                },
                () => {
                    setErrorMsg('El producto seleccionado no posee stock')
                }
            );
        }
    }

    const changeAmount = (event) => {
        const newValue = event.target.value;
        if (newValue >= 1 && newValue <= 35) {
            AddToCart(newValue)
        }
    }

    return(
        <div>
            {
                isLoading ? (
                    <StyledAddButton>
                        <div className="add-button-loader">
                            <div className="loader-web">
                                <Loader width='2vw'/>
                            </div>
                            <div className="loader-mobile">
                                <Loader width='8vw'/>
                            </div>
                        </div>
                    </StyledAddButton>
                ) : (
                    <StyledAddButton>
                        {
                            amount === 0 ? (
                                <button className={"btn-agregar"} onClick={() => AddToCart(1)}>
                                    {productButton}
                                </button>
                            ):(
                                <div className={"agregado"}>
                                    {
                                        amount > 1 ? (
                                            <AiOutlineMinus  className="change-amount-button" onClick={RemoveFromCart}/>
                                        ):(
                                            <TbTrash className="change-amount-button" onClick={RemoveFromCart}/>
                                        )
                                    }
                                    <input className={"amount"} type="number" value={amount} onChange={changeAmount}/>
                                    {/*    <input type="text" value={amount}/>*/}
                                    {/*    /!*{amount}*!/*/}
                                    {/*</input>*/}
                                    <AiOutlinePlus className="change-amount-button" onClick={() => AddToCart(Number(amount)+1)}/>
                                </div>
                            )
                        }
                    </StyledAddButton>
                )
            }
        </div>
    )
}


const StyledAddButton = styled.div`
  .add-button-loader {
    display: flex;
    width: 100%;
    height: 5vw;
    justify-content: center;
    align-items: center;
  }
  
  .loader-web {
    
  }

  .loader-mobile {
    display: none;
  }
  
  .btn-agregar {
    background-color: darkred;
    margin: 1.2vw 1vw;
    color: white;
    width: 85%;
    height: 2.5vw;
    padding: 0.1vw;
    border-radius: 0.35vw;
    font-size: 1vw;
    /*transition: all 0.05s;*/
  }
  
  .btn-agregar:hover {
    /*transform: translateY(-0.5px);*/
    box-shadow: 0 0 0.2vw 0.15vw rgba(0,0,0,0.2);
  }
  
  .agregado {
    display: inline-flex;
    /*background-color: rgb(217, 217, 230);*/
    margin-top: 1.2vw;
    width: 100%;
    height: 2.5vw;
    border-radius: 0.7vw;
    font-size: 1.3vw;
    color: black;
    /*box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.25);*/
    justify-content: center;
    align-items: center;
  }
  
  .amount {
    margin: 0.3vw 1vw;
    width: 25%;
    text-align: center;
    font-size: 1.3vw;
    padding: 0.35vw 0.5vw;
    border: none;
    box-shadow: 0 0 0.1vw 0.08vw rgba(0,0,0,0.2);
    border-radius: 0.4vw;
    font-weight: 400;
  }
  
  .change-amount-button {
    color: darkred;
    border-radius: 0.4vw;
    cursor: pointer;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }


  @media(max-device-width: 700px){
    .add-button-loader {
      display: flex;
      width: 100%;
      height: 10vw;
      margin-top: 12%;
      justify-content: center;
      align-items: center;
    }

    .loader-web {
      display: none;
    }

    .loader-mobile {
      display: block;
    }
    
    .btn-agregar {
      background-color: darkred;
      margin: 16% 1vw;
      color: white;
      width: 90%;
      height: 9vw;
      padding: 0.1vw;
      border-radius: 2vw;
      font-size: 4vw;
      /*transition: all 0.05s;*/
    }
    
    .btn-agregar:hover {
      /*transform: translateY(-0.5px);*/
      box-shadow: 0 0 0 0 rgba(0,0,0,0.2);
    }
    
    .agregado {
      display: inline-flex;
      /*background-color: rgb(217, 217, 230);*/
      margin-top: 16%;
      width: 100%;
      height: 7vw;
      padding: 0.1vw;
      border-radius: 0.7vw;
      font-size: 6vw;
      color: black;
      /*box-shadow: 0 0 0.2vw 0.1vw rgba(0,0,0,0.25);*/
      justify-content: center;
      align-items: center;
      gap: 2vw;
    }
    
    .amount {
      margin: 0.3vw 1vw;
      width: 30%;
      text-align: center;
      /*display: flex;*/
      /*justify-content: center;*/
      font-size: 5vw;
      padding: 0.35vw 0.5vw;
      border: none;
      box-shadow: 0 0 0.5vw 0.1vw rgba(0,0,0,0.2);
      border-radius: 0.4vw;
      font-weight: 400;
    }
    
    .change-amount-button {
      color: darkred;
      /*background-color: darkred;*/
      border-radius: 0.4vw;
      /*border-top: 0.2vw solid darkred;*/
      /*border-bottom: 0.2vw solid darkred;*/
      /*box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
      cursor: pointer;
    }
  }

`