
const PricesSorter = ({ item }) => {

    const options = [
        { key: 'jumbo-price', label: 'Jumbo', price: item.jumboPrice },
        { key: 'carrefour-price', label: 'Carrefour', price: item.carrefourPrice },
        { key: 'coto-price', label: 'Coto', price: item.cotoPrice },
    ];

    // Ordenar las opciones: primero por precios mayores a cero, dejando al final los productos sin stock
    const sortedOptions = options.sort((a, b) => {
        if (a.price > 0 && b.price > 0) {
            return a.price - b.price;
        } else if (a.price <= 0 && b.price <= 0) {
            // Si ambos tienen precio <= 0, mantener el orden original
            return 0;
        } else {
            // Mover opciones con precio <= 0 al final
            return a.price > 0 ? -1 : 1;
        }
    });

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }

    return (
        <select className="prices-selector" defaultValue={sortedOptions[0].key} onChange={() => {}}>
            {sortedOptions.map(({ key, label, price }) => (
                <option key={key} className="price" value={key} disabled>
                    {label}:&nbsp;&nbsp;
                    {price > 0 ? (
                        <div className="price-number">{formatNumber(price)}</div>
                    ) : (
                        <div>sin stock</div>
                    )}
                </option>
            ))}
        </select>
    );
}

export default PricesSorter;