import "./checkoutPage.css"
import {Link} from "react-router-dom";
import Navbar from "../components/Navbar";
import * as React from "react";
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";

export const CheckoutPage = (props) => {

    const auth = useAuthProvider()
    const token = auth.getToken()
    const mySystem = useMySystem()

    const jumboPrice = props.jumboPrice
    const carrefourPrice = props.carrefourPrice
    const cotoPrice = props.cotoPrice
    const [smarketPrice, setSmarketPrice] = useState()

    const handleClick = () => {
        // window.location.href = 'https://www.mercadopago.com.ar';
        window.open('https://www.mercadolibre.com/jms/mla/lgz/msl/login/H4sIAAAAAAAEAy2OwQ4CIRBD_6Vnsnvn6I-QkR1YIggZRtFs9t8N6rFp-9oDucZ0d_puDAt-tZx8Uhi0TBqqFJc2WJQGg56U_zLTjJBQYWXpsMcERd4uHKpMlMqDYUAP3V3IdcD-pmCQuuOXstwpu8HXZ-LpBsp9NmKFxa7aul3XMcZSWDxttVGsi69lIVlhIBxTVxae975jp0Ggrk6F_A32yzs__UQ7zeEAAAA/user', '_blank');
    }

    useEffect(() => {
        mySystem.getSmarketPrice(token, (smarketPrice) => setSmarketPrice(smarketPrice))
    }, [])

    return(
        <div className="checkout-container" style={{overflowX: "hidden", overflowY: "hidden"}}>
            <Navbar/>
            <div style={{width: "60vw", height: "30vw", margin: "7vw 20vw", borderRadius: "1vw", backgroundColor: "rgb(230, 230, 240)"}} >
                <div style={{fontSize: "1.5vw", fontWeight: "bold", textAlign: "center", padding: "1.3vw"}} >
                    Informacion de pago
                </div>
                <div style={{fontSize: "1.2vw", textAlign: "center", padding: "1.3vw"}} >
                    Para efectuar la compra se debe hacer una transferencia por Mercado Pago
                    a la cuenta de la empresa
                </div>
                <div style={{fontSize: "1.2vw", textAlign: "center", padding: "1.3vw", margin: "1vw"}}>
                    Alias: smarket.lat
                </div>
                <div style={{fontSize: "1.2vw", textAlign: "center", padding: "1.3vw", margin: "1vw"}}>
                    Monto a pagar: ${smarketPrice}
                </div>
                <button onClick={() => {handleClick()}} style={{fontSize: "1.5vw", borderRadius: "0.7vw", width: "30%", backgroundColor: "darkred", margin: "5vw 35%"}}>
                    Pagar
                </button>
                {/*<Link className="volver" to={"www.mercadopago.com.ar"} style={{fontSize: "1.5vw", borderRadius: "0.7vw", width: "15vw"}} >*/}
                {/*    Pagar*/}
                {/*</Link>*/}
            </div>
        </div>
    )
}