import config from "../config/config";

const restApiEndpoint = config.apiUrl;


const MySystem = {
    login: (credentials, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        }).then(resp => {
            if (resp.status === 201) {
                resp.json().then(body => okCallback(body))
            } else {
                errorCallback("Invalid user or password")
            }
        }).catch(e => errorCallback("Unable to connect to Smarket API"))
    },
    loginAsAdmin: (credentials, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/adminAuthentication`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        }).then(resp => {
            if (resp.status === 201) {
                resp.json().then(body => okCallback(body))
            } else {
                errorCallback("Invalid user or password")
            }
        }).catch(e => errorCallback("Unable to connect to Smarket API"))
    },
    register: (user, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        }).then(resp => {
            if (resp.status === 201) {
                okCallback()
            } else {
                errorCallback()
            }
        })
    },
    registerSupermarket: (supermarket, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/registerSupermarket`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(supermarket)
        }).then(resp => {
            if (resp.status === 201) {
                okCallback()
            } else {
                errorCallback()
            }
        })
    },
    registerCategory(category, okCallback, errorCallback) {
        fetch(`${restApiEndpoint}/registerCategory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(category)
        }).then(resp => {
            if (resp.status === 201) {
                okCallback()
            } else {
                errorCallback()
            }
        })
    },
    registerProduct: (product, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/registerProduct`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(product)
        }).then(resp => {
            if (resp.status === 201) {
                okCallback()
            } else {
                errorCallback()
            }
        })
    },
    registerDiscount: (discount, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/registerDiscount`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(discount)
        }).then(resp => {
            if (resp.status === 201) {
                okCallback()
            } else {
                errorCallback()
            }
        })
    },
    addProducts: (token, productId, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/addProduct`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            },
            body: JSON.stringify(productId)
        }).then(resp => {
            if (resp.status === 201) {
                okCallback()
            } else {
                errorCallback()
            }
        })
    },
    addRealProducts: (productAndCartId, errorCallback) => {
        fetch(`${restApiEndpoint}/addProduct`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "ngrok-skip-browser-warning": "true",
            },
            body: JSON.stringify(productAndCartId)
        }).then(resp => {
            if (resp.status !== 201) {
                errorCallback()
            }
        })
    },
    removeItems: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/removeItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(resp => {
            if (resp.status === 201) {
                okCallback()
            } else {
                errorCallback()
            }
        })
    },
    removeCategory: (categoryName, errorCallback) => {
        console.log(categoryName)
        fetch(`${restApiEndpoint}/removeCategory`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryName)
        }).then(resp => {
            if (resp.status !== 201) {
                errorCallback()
            }
        })
    },
    removeSupermarket: (supermarket, errorCallback) => {
        fetch(`${restApiEndpoint}/removeSupermarket`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(supermarket)
        }).then(resp => {
            if (resp.status !== 201) {
                errorCallback()
            }
        })
    },
    setDeliveryLocation: (token, deliveryData, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/deliveryLocation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(deliveryData)
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(url => okCallback(url))
            } else {
                errorCallback()
            }
        })
    },
    setContactInfo: (token, contactInfo, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/contactInfo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(contactInfo)
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(url => okCallback(url))
            } else {
                errorCallback()
            }
        })
    },
    buyCart: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/buyCart`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(url => okCallback(url))
            } else {
                errorCallback()
            }
        })
    },
    setBoughtProduct: (token, data, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/setIsBoughtProduct`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(url => okCallback(url))
            } else {
                errorCallback()
            }
        })
    },
    setBoughtOrder: (token, cartId, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/setIsBoughtOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(cartId)
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(url => okCallback(url))
            } else {
                errorCallback()
            }
        })
    },
    setDeliveredOrder: (token, cartId, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/setDeliveredOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(cartId)
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(url => okCallback(url))
            } else {
                errorCallback()
            }
        })
    },
    // emptyCart: (token, errorCallback) => {
    //     fetch('http://localhost:4321/emptyCart', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token
    //         },
    //     }).then(resp => {
    //         if (resp.status !== 201) {
    //             errorCallback()
    //         }
    //     })
    // },
    getAmount:(token, setIsLoading, productId, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/getAmount`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(productId)
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(amount => okCallback(amount))
                setIsLoading(false)
            } else {
                errorCallback()
            }
        })
    },
    getAmountAdmin:(token, data, setIsLoading, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/getAmountAdmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(amount => okCallback(amount))
                setIsLoading(false)
            } else {
                errorCallback()
            }
        })
    },
    getIsBought:(token, data, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/getIsBought`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(isBought => okCallback(isBought))
            } else {
                errorCallback()
            }
        })
    },
    listSupermarkets: (token, setIsLoading, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/listSupermarkets`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(supermarkets => okCallback(supermarkets))
                setIsLoading(false)
            } else {
                errorCallback()
            }
        })
    },
    listProducts: (category, token, setIsLoading, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/product/`+category, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(products => okCallback(products))
                setIsLoading(false)
            } else {
                errorCallback()
            }
        })
    },
    listCategories: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/listCategories`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(categories => okCallback(categories))
                // setIsLoading(false)
            } else {
                errorCallback()
            }
        })
    },
    listFatherCategories(token, okCallback, errorCallback) {
        fetch(`${restApiEndpoint}/listFatherCategories`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(fatherCategories => okCallback(fatherCategories))
            } else {
                errorCallback()
            }
        })
    },
    showCart: (setIsLoading, token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/carrito`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(cartItems => okCallback(cartItems))
                setIsLoading(false)
            } else {
                errorCallback()
            }
        })
    },
    showCartItems: (setIsLoading, token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/cartProducts`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(cartItems => okCallback(cartItems))
                setIsLoading(false)
            } else {
                errorCallback()
            }
        })
    },
    getExpensivePrice: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/totalExpensive`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(price => okCallback(price))
            } else {
                errorCallback()
            }
        })
    },
    getSmarketPrice: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/totalSmarket`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(price => okCallback(price))
            } else {
                errorCallback()
            }
        })
    },
    getJumboPrice: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/totalJumbo`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(price => okCallback(price))
            } else {
                errorCallback()
            }
        })
    },
    getCarrefourPrice: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/totalCarrefour`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(price => okCallback(price))
            } else {
                errorCallback()
            }
        })
    },
    getVeaPrice: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/totalVea`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(price => okCallback(price))
            } else {
                errorCallback()
            }
        })
    },
    getCart: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/getCart`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(cart => okCallback(cart))
            } else {
                errorCallback()
            }
        })
    },
    getLastDeliveryData: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/getDeliveryData`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(deliveryData => okCallback(deliveryData))
            } else {
                errorCallback()
            }
        })
    },
    getPendingOrders: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/pendingOrders`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(pendingOrders => okCallback(pendingOrders))
            } else {
                errorCallback()
            }
        })
    },
    getMyOrders: (token, okCallback, errorCallback) => {
        fetch(`${restApiEndpoint}/myOrders`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                "ngrok-skip-browser-warning": "true",
            }
        }).then(resp => {
            if (resp.status === 200) {
                resp.json().then(orders => okCallback(orders))
            } else {
                errorCallback()
            }
        })
    },
}

const useMySystem = () => MySystem

export {useMySystem};
