import * as React from 'react'
import './home.css'
import {Link} from "react-router-dom";
import Navbar from "../components/Navbar";
import IconoCarrito from "../components/IconoCarrito";
import {useMySystem} from "../service/mySystem";
import {useEffect, useRef, useState} from "react";
import {useAuthProvider} from "../auth/auth";
import Loader from "../components/Loader";
import banner2 from "./images/banners/SmarketBanner2.png"
import bannerMobile from "./images/banners/Banner mobile 3.png"
import ProgressBar from "../components/ProgressBar";
import {useNavigate} from "react-router";
import {IoIosArrowBack} from "react-icons/io";
import HomeCategory from "../components/HomeCategory";

export const HomePage = () => {

    const [bebidas, setBebidas] = useState([])
    const [lacteos, setLacteos] = useState([])
    const [frescos, setFrescos] = useState([])
    const [congelados, setCongelados] = useState([])
    const [desayuno, setDesayuno] = useState([])
    const [almacen, setAlmacen] = useState([])
    const [perfumeria, setPerfumeria] = useState([])
    const [cuidadoCapilar, setCuidadoCapilar] = useState([])
    const [limpieza, setLimpieza] = useState([])
    const [hogar, setHogar] = useState([])

    const mySystem = useMySystem();
    const [categories, setCategories] = useState([])
    const [aux, setAux] = useState([])
    const auth = useAuthProvider();
    // const token = auth.getAdminToken();
    const token = auth.getToken();

    const [cartItems, setCartItems] = useState([]);

    const bebidasAvailable = (subcategoria) => {
        // return subcategoria.name !== "aguas" && subcategoria.name !== "alcoholes" && subcategoria.name !== "aperitivos" && subcategoria.name !== "cervezas" && subcategoria.name !== "espumantes" && subcategoria.name !== "gaseosas" && subcategoria.name !== "isotonicas" && subcategoria.name !== "jugos" && subcategoria.name !== "vinos" && subcategoria.name !== "whiskys";
        return subcategoria.name !== "aguas" && subcategoria.name !== "alcoholes" && subcategoria.name !== "" && subcategoria.name !== "cervezas" && subcategoria.name !== "" && subcategoria.name !== "gaseosas" && subcategoria.name !== "" && subcategoria.name !== "jugos" && subcategoria.name !== "" && subcategoria.name !== "vinos";
    }
    const desayunoAvailable = (subcategoria) => {
        // return subcategoria.name !== "alfajores" && subcategoria.name !== "bizcochuelos-budines-y-magdalenas" && subcategoria.name !== "cafes" && subcategoria.name !== "cereales" && subcategoria.name !== "endulzantes" && subcategoria.name !== "galletitas" && subcategoria.name !== "golosinas-y-chocolates" && subcategoria.name !== "infusiones" && subcategoria.name !== "mermeladas" && subcategoria.name !== "tostadas-y-grisines" && subcategoria.name !== "yerbas";
        return subcategoria.name !== "alfajores" && subcategoria.name !== "" && subcategoria.name !== "cafes" && subcategoria.name !== "" && subcategoria.name !== "" && subcategoria.name !== "galletitas" && subcategoria.name !== "golosinas-y-chocolates" && subcategoria.name !== "" && subcategoria.name !== "" && subcategoria.name !== "" && subcategoria.name !== "yerbas";
    }
    const almacenAvailable = (subcategoria) => {
        // return subcategoria.name !== "aceites-y-vinagres" && subcategoria.name !== "aderezos" && subcategoria.name !== "arroz-y-legumbres" && subcategoria.name !== "caldos-sopas-y-pures" && subcategoria.name !== "conservas" && subcategoria.name !== "especias" && subcategoria.name !== "harinas" && subcategoria.name !== "panificados" && subcategoria.name !== "pastas" && subcategoria.name !== "reposteria-y-postres" && subcategoria.name !== "snacks";
        return subcategoria.name !== "aceites-y-vinagres" && subcategoria.name !== "aderezos" && subcategoria.name !== "" && subcategoria.name !== "" && subcategoria.name !== "" && subcategoria.name !== "especias" && subcategoria.name !== "" && subcategoria.name !== "" && subcategoria.name !== "pastas" && subcategoria.name !== "" && subcategoria.name !== "snacks";
    }
    const lacteosAvailable = (subcategoria) => {
        // return subcategoria.name !== "cremas" && subcategoria.name !== "dulce-de-leches" && subcategoria.name !== "leches" && subcategoria.name !== "mantecas" && subcategoria.name !== "postres" && subcategoria.name !== "quesos" && subcategoria.name !== "yogures";
        return true
    }
    const frescosAvailable = (subcategoria) => {
        // return subcategoria.name !== "dulces" && subcategoria.name !== "encrutidos" && subcategoria.name !== "fiambres" && subcategoria.name !== "huevos" && subcategoria.name !== "salchichas" && subcategoria.name !== "tapas-y-pastas-frescas";
        return true
    }
    const congeladosAvailable = (subcategoria) => {
        // return subcategoria.name !== "comidas-congeladas" && subcategoria.name !== "frutas-y-verduras" && subcategoria.name !== "hamburguesas-y-rebozados" && subcategoria.name !== "helados" && subcategoria.name !== "papas" && subcategoria.name !== "pescados";
        return true
    }
    const perfumeriaAvailable = (subcategoria) => {
        // return subcategoria.name !== "accesorios-dentales" && subcategoria.name !== "afeitado" && subcategoria.name !== "cepillos-dentales" && subcategoria.name !== "cuidado-de-la-piel" && subcategoria.name !== "depilacion" && subcategoria.name !== "desodorantes" && subcategoria.name !== "enjuagues-bucales" && subcategoria.name !== "farmacia" && subcategoria.name !== "jabones" && subcategoria.name !== "pastas-dentales" && subcategoria.name !== "proteccion-femenina";
        return true
    }
    const cuidadoCapilarAvailable = (subcategoria) => {
        // return subcategoria.name !== "acondicionadores" && subcategoria.name !== "coloracion" && subcategoria.name !== "fijacion" && subcategoria.name !== "shampoos" && subcategoria.name !== "tratamientos-y-cremas";
        return true
    }
    const limpiezaAvailable = (subcategoria) => {
        // return subcategoria.name !== "articulos-de-limpieza" && subcategoria.name !== "calzado" && subcategoria.name !== "desodorantes-de-ambiente" && subcategoria.name !== "insecticidas" && subcategoria.name !== "lavado-de-ropa" && subcategoria.name !== "lavandinas" && subcategoria.name !== "limpieza-de-bano" && subcategoria.name !== "limpieza-de-cocina" && subcategoria.name !== "papeles" && subcategoria.name !== "pisos-y-muebles";
        return true
    }
    const hogarAvailable = (subcategoria) => {
        // return subcategoria.name !== "automotor" && subcategoria.name !== "ferreteria" && subcategoria.name !== "libreria" && subcategoria.name !== "mascotas" && subcategoria.name !== "";
        return true
    }


    const titles = ["Bebidas", "Desayuno y Merienda", "Almacen", "Lacteos", "Frescos", "Congelados", "Perfumeria", "Cuidado Capilar", "Limpieza", "Hogar"]
    const subcategoriesList = [bebidas, desayuno, almacen, lacteos, frescos, congelados, perfumeria, cuidadoCapilar, limpieza, hogar]
    const subcategoriesAvailableList = [bebidasAvailable, desayunoAvailable, almacenAvailable, lacteosAvailable, frescosAvailable, congeladosAvailable, perfumeriaAvailable, cuidadoCapilarAvailable, limpiezaAvailable, hogarAvailable]
    // const titles = ["Bebidas", "Lacteos", "Desayuno y Merienda", "Almacen", "Congelados", "Perfumeria"]
    // const subcategoriesList = [bebidas, lacteos, desayuno, almacen, congelados, perfumeria]
    // const subcategoriesAvailableList = [bebidasAvailable, lacteosAvailable, desayunoAvailable, almacenAvailable, congeladosAvailable, perfumeriaAvailable]

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        mySystem.listCategories(token, (categories) => setCategories(categories));
        mySystem.showCart(setIsLoading, token, (cartItems) => setCartItems(cartItems));
        categories.sort();
    },[])


    const fillFatherCategories = () => {
        if (bebidas.length === 0 && lacteos.length === 0 && frescos.length === 0 && desayuno.length === 0 && almacen.length === 0 &&  congelados.length === 0 && perfumeria.length === 0 && cuidadoCapilar.length === 0 && limpieza.length === 0 && hogar.length === 0) {
            for (let i = 0; i < categories.length; i++) {
                // console.log(categories[i].name + categories[i].fatherCategory + i + ' ' + categories.length)
                //cuando se llenan mal es porque hay una categoria que no encuentra su logo en images (o sea, no hay una imagen con el nombre de esa categoria)
                if (categories[i].fatherCategory === "Bebidas"){
                    bebidas.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
                else if (categories[i].fatherCategory === "Lacteos"){
                    lacteos.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
                else if (categories[i].fatherCategory === "Frescos"){
                    frescos.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
                else if (categories[i].fatherCategory === "Congelados"){
                    congelados.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
                else if (categories[i].fatherCategory === "Desayuno"){
                    desayuno.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
                else if (categories[i].fatherCategory === "Almacen"){
                    almacen.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
                else if (categories[i].fatherCategory === "Perfumeria"){
                    perfumeria.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
                else if (categories[i].fatherCategory === "Cuidado Capilar"){
                    cuidadoCapilar.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
                else if (categories[i].fatherCategory === "Limpieza"){
                    limpieza.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
                else if (categories[i].fatherCategory === "Hogar"){
                    hogar.push(
                        {
                            name: categories[i].name,
                            logo: require('./images/categories/' + categories[i].name.toLowerCase() + '.png'),
                        }
                    )
                }
            }
            sortFatherCategories()
        }
    }

    const sortFatherCategories = () => {
        let bebidasOrderPriorities = ["aguas", "alcoholes", "cervezas", "gaseosas", "jugos"];
        let desayunoOrderPriorities = ["alfajores", "cafes", "galletitas", "golosinas-y-chocolates",  "yerbas"];
        let almacenOrderPriorities = ["aceites-y-vinagres", "aderezos", "especias", "pastas", "snacks"];

        sortEachCategory(bebidas, bebidasOrderPriorities);
        sortEachCategory(desayuno, desayunoOrderPriorities);
        sortEachCategory(almacen, almacenOrderPriorities);
    }

    const sortEachCategory = (category, orderPriorities) => {
        category.sort((a, b) => {
            let indexA = orderPriorities.indexOf(a.name);
            let indexB = orderPriorities.indexOf(b.name);

            if (indexA !== -1 && indexB !== -1) {
                // Ambos elementos están en la lista de prioridades, se ordenan según su posición allí
                return indexA - indexB;
            } else if (indexA !== -1) {
                // Solo el elemento 'a' está en la lista de prioridades, por lo que va primero
                return -1;
            } else if (indexB !== -1) {
                // Solo el elemento 'b' está en la lista de prioridades, por lo que va primero
                return 1;
            } else {
                // Ninguno de los elementos está en la lista de prioridades, se ordenan alfabéticamente
                return a.name.localeCompare(b.name);
            }
        });
    }


    return(
        <div className="container">
            <div>{fillFatherCategories()}</div>
            <Navbar/>
            <div className="carrito-web">
                <IconoCarrito cartItems={cartItems}/>
            </div>
            {/*<div className="progressbar">*/}
            {/*    <ProgressBar/>*/}
            {/*</div>*/}
            <div style={{height: '7vw'}}></div>
            <img src={banner2} alt="" className="home-banner"/>
            <img src={bannerMobile} alt="" className="home-banner-mobile"/>
            {
                titles.map((title, index) => {
                    return (
                        <HomeCategory
                            title={title}
                            subcategories={subcategoriesList[index]}
                            subcategoriesAvailable={subcategoriesAvailableList[index]}
                            isLoading={isLoading}
                        />
                    )
                })
            }
            <div style={{ height: '8vw' }}/>
        </div>
    )
}