import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {BsCart2} from "react-icons/bs";
import "./iconoCarrito.css"
import {Link} from "react-router-dom";
import {useAuthProvider} from "../auth/auth";
import {useMySystem} from "../service/mySystem";


function IconoCarrito(props){

    const navigate = useNavigate()
    const setState = useState('')
    const auth = useAuthProvider()
    const token = auth.getToken()
    const mySystem = useMySystem()
    const cartItems = props.cartItems

    const state = {clicked: false}

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
    }, [props.updateCartAmount])

    const handleClick = () => {
        setState({clicked: !state.clicked})
    }

    return(
        <Link to={"/carrito"}>
            <BsCart2 className="icono" onClick={handleClick}>
            </BsCart2>
            <div className="cart-amount">
                {cartItems.length}
            </div>
        </Link>
    )
}
export default IconoCarrito