import styled from "styled-components";
import {useState} from "react";
import ProgressBar from "./ProgressBar";

const ProgressBarButton = (props) => {

    const [clicked, setClicked] = useState(false)

    return (
        <div>
            <ProgressContainer>
                <ProgressButton onClick={() => setClicked(!clicked)}>mínimos</ProgressButton>
                {
                    clicked && <ProgressBar aux={props.aux} />
                }
            </ProgressContainer>
        </div>
    )
}

export default ProgressBarButton

const ProgressContainer = styled.div`
  //width: 3vw;
  height: 1.5vw;
  box-shadow: 0 0 0.1vw 0.1vw rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-bottom-left-radius: 0.2vw;
  border-bottom-right-radius: 0.2vw;
  font-size: 1vw;
  font-weight: 500;
  z-index: 150;
`

const ProgressButton = styled.div`
  height: 1.5vw;
  cursor: pointer;
  padding: 0.4vw 0.5vw 0.4vw;
  user-select: none; /*hace que no se pueda seleccionar el elemento (que se ponga en azul)*/
  color: darkred;
`