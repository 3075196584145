import {useNavigate} from "react-router";
import {FaArrowLeft} from "react-icons/fa";

const GoBackButton = () => {

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navega hacia atrás en la pila de navegación
    };

    return (
        <button onClick={goBack}
                style={{
                    width: '7%',
                    height: '2.5vw',
                    backgroundColor: 'rgb(245, 245, 253)',
                    margin: '0 0 -1vw 0',
                    // backgroundColor: 'white',
                    // margin: '0 1vw -1vw 0',
                    // boxShadow: '0 0 0.1vw 0.1vw rgba(0,0,0,0.15)',
                    color: 'darkred',
                    fontSize: '1.3vw',
                    borderRadius: '0.5vw 0 0 0.5vw',
                    padding: '0.6vw 0.5vw',
                    border: 'none',
                    cursor: 'pointer',
                }}
        >
            {/*<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 -50 448 512">*/}
            {/*    /!*<style>svg{fill:#ffffff}</style>*!/*/}
            {/*    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>*/}
            {/*</svg>*/}
            <FaArrowLeft />
        </button>
    )
}

export default GoBackButton;