import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useMySystem} from "../service/mySystem";
import {Link} from "react-router-dom";
import * as React from "react";
import {useAuthProvider} from "../auth/auth";

export const FailurePage = () => {

    const navigate = useNavigate();
    const auth = useAuthProvider();
    const userEmail = auth.getToken();

    useEffect(() => {
        navigate("/carrito")
    },[])

    return(
        <div></div>
    )
}