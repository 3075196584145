import './deliveryData.css'
import * as React from "react";
import {useState} from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";

const ContactInfo = (props) => {

    const name = props.name
    const lastname = props.lastname
    const number = props.number
    const setName = props.setName
    const setLastname = props.setLastname
    const setNumber = props.setNumber

    const handleClick = (event) => {
        event.preventDefault();
        // const deliveryData = {
        //     province: name,
        //     city: lastname,
        //     number: number,
        // }
        // mySystem.setContactInfo(token, deliveryData)
        props.setAccordionOpen(false)
        props.setNextAccordionOpen(true)
        props.setContactDone(true)
    };

    return(
        <div className='delivery-container' style={{ width: '50%', marginLeft: '25%' }}>
            <form id="delivery" className="delivery-form" onSubmit={handleClick}>
                <div className='delivery-title'>
                    <h2>Ingrese su información de contacto</h2>
                </div>
                <div className='delivery-details'>
                    <div className='delivery-fields'>
                        <input type="text" placeholder="Nombre" value={name} onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className='delivery-fields'>
                        <input type="text" placeholder="Apellido" value={lastname} onChange={(e) => setLastname(e.target.value)} required />
                    </div>
                    <div className='delivery-fields'>
                        <input type="text" placeholder="Numero de telefono" value={number} onChange={(e) => setNumber(e.target.value)} required />
                    </div>
                </div>
                <button type="submit" className='delivery-button' style={{ width: '60%', marginLeft: '20%' }} >
                    Continuar
                </button>
            </form>
        </div>
    )
}

export default ContactInfo