import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router";
import {useMySystem} from "../service/mySystem";
import './register.css'
import {Link} from "react-router-dom";
import element1 from "./images/backgrounds/elements/Rombo rojo 2.png"
import element2 from "./images/backgrounds/elements/Rombo rojo 2.png"

import { useRef } from 'react';
import emailjs from '@emailjs/browser';

function setToken(userToken){
    sessionStorage.setItem('token', JSON.stringify(userToken));
}

export const RegisterPage = () => {
    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [cellphone, setCellphone] = useState('')
    const [street, setStreet] = useState('')
    const [number, setNumber] = useState('')
    const [city, setCity] = useState('')
    const [password, setPassword] = useState('')
    const [notes, setNotes] = useState('')
    const [errorMsg, setErrorMsg] = useState(undefined)
    const navigate = useNavigate();
    const mySystem = useMySystem();

    const form = useRef();
    const [message, setMessage] = useState('Gracias por registrarte en nuestra plataforma.');

    const sendEmail = () => {
        //confirmation template: template_4xoisic
        //validation template: template_866l1e6
        // emailjs.sendForm('service_m7fennm', 'template_4xoisic', form.current, 'BIMokn9R2RpkfxbmE')
        //     .then((result) => {
        //         console.log(result.text);
        //     }, (error) => {
        //         console.log(error.text);
        //     });
    };

    const loginUser = (credentials) => {
        mySystem.login(
            credentials,
            (token) => {
                setToken(token)
                navigate("/home", {replace: true});
            },
            (msg) => {
                setErrorMsg(msg)
                setUsername('')
                setPassword('')
            })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        registerUser({
            name: name,
            cellphone: cellphone,
            email: username.toLowerCase(),
            password: password,
            street: street,
            number: number,
            city: city,
            notes: notes
        })
    }

    const registerUser = (user) => {
        mySystem.register(
            user,
            async () => {
                sendEmail();
                await loginUser({
                    email: username,
                    password: password
                })
            },
            () => {
                setErrorMsg('¡El usuario ya existe!')
                setName('')
                setUsername('')
                setCellphone('')
                setStreet('')
                setNumber('')
                setCity('')
                setPassword('')
                setNotes('')
            }
        )
    }

    const nameChange = (event) => {
        setName(event.target.value)
    }

    const usernameChange = (event) => {
        setUsername(event.target.value)
    }

    const cellphoneChange = (event) => {
        setCellphone(event.target.value)
    }

    const streetChange = (event) => {
        setStreet(event.target.value)
    }

    const numberChange = (event) => {
        setNumber(event.target.value)
    }

    const cityChange = (event) => {
        setCity(event.target.value)
    }

    const passwordChange = (event) => {
        setPassword(event.target.value)
    }

    const notesChange = (event) => {
        setNotes(event.target.value)
    }

    return(
        <div className="background-image">
            <img src={element1} alt="" className="elementIZQ"/>
            <img src={element2} alt="" className="elementDER"/>
            <div className="background-filter"></div>
            {errorMsg && <div className="alert-register alert-danger" role="alert">{errorMsg}</div>}
            <div className="register-form">
                <div className="logo-r"/>
                <h4>Por favor complete los siguientes campos</h4>
                <form
                    id="register"
                    className="input-group-r"
                    ref={form}
                    onSubmit={handleSubmit}
                >
                    <input type="text" className="input-field-r" placeholder="Nombre de usuario" value={name} onChange={nameChange} name="to_name" required/>
                    <input type="email" className="input-field-r" placeholder="Email" value={username} onChange={usernameChange} name="user_email" required/>
                    <input type="password" className="input-field-r" placeholder="Contraseña" value={password} onChange={passwordChange} required/>
                    <input type="hidden" name="message" value={message} />
                    <button type="submit" className="register-btn" >Registrarse</button>
                    <p className="links3"> ¿Ya tienes una cuenta? <Link to="/login"> Inicia Sesión</Link></p>
                </form>
            </div>
        </div>
    )
}