import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router";
import {useMySystem} from "../service/mySystem";
import {Link, useSearchParams} from "react-router-dom";
import './login.css'
import element1 from "./images/backgrounds/elements/Rombo rojo 2.png"
import element2 from "./images/backgrounds/elements/Rombo rojo 2.png"

function setToken(userToken){
    sessionStorage.setItem('token', JSON.stringify(userToken));
}

export const LoginPage = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState(undefined)
    const navigate = useNavigate()
    const mySystem = useMySystem()
    const [searchParams] = useSearchParams();
    const isOk = searchParams.get("ok")

    function loginUser(credentials){
        mySystem.login(
            credentials,
            (token) => {
            setToken(token)
            navigate("/home", {replace: true});
        },
            (msg) => {
                setErrorMsg(msg)
                setUsername('')
                setPassword('')
            })
    }

    function loginAdmin(credentials){
        mySystem.loginAsAdmin(
            credentials,
            (token) => {
                setToken(token)
                navigate("/admin", {replace: true});
            },
            (msg) => {
                setErrorMsg(msg)
                setUsername('')
                setPassword('')
            })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (username === "admin@gmail.com")
            await loginAdmin({
                email: username.toLowerCase(),
                password: password
            })
        else
            await loginUser({
                email: username.toLowerCase(),
                password: password
            })
    }

    const usernameChange = (event) => {
        setUsername(event.target.value)
    }

    const passwordChange = (event) => {
        setPassword(event.target.value)
    }

    return(
        <div className="background-image">
            <img src={element1} alt="" className="elementIZQ"/>
            <img src={element2} alt="" className="elementDER"/>
            <div className="background-filter"></div>
            {isOk && <div className="alert-user alert-success" role="alert">Usuario Creado</div>}
            {errorMsg && <div className="alert-user alert-warning" role="alert">{errorMsg}</div>}
            <div className="login-form">
                <div className="logo-l"/>
                <h2>Iniciar Sesión</h2>
                <form id="login" className="input-group-l" onSubmit={handleSubmit}>
                        <input type="email" value={username} onChange={usernameChange} className="input-field-l" placeholder="Email" name="user_email" required/>
                        <input type="password" value={password} onChange={passwordChange} className="input-field-l" placeholder="Contraseña" required/>
                        <div className="check-box">
                            <input type="checkbox"/><scan>Recordar Contraseña</scan>
                        </div>
                        <button type="submit" className="login-btn">Iniciar Sesión</button>
                    <p className="links2"> Crear cuenta nueva -<Link to="/register"> Registrate</Link></p>
                </form>
            </div>
        </div>
    )
}