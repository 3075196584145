import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useMySystem} from "../service/mySystem";
import './categoryRegister.css'
import {useAuthProvider} from "../auth/auth";

export const RemoveSupermarketPage = () => {

    const [errorMsg, setErrorMsg] = useState(undefined)
    const [supermarket, setSupermarket] = useState('');
    const [supermarkets, setSupermarkets] = useState([]);
    const navigate = useNavigate();
    const mySystem = useMySystem();
    const auth = useAuthProvider();
    const token = auth.getAdminToken();

    useEffect(() => {
        mySystem.listSupermarkets(token, (supermarkets) => setSupermarkets(supermarkets))
        supermarkets.sort();
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        removeSupermarket({
            supermarket: supermarket,
        })
    }

    const removeSupermarket = (supermarket) => {
        mySystem.removeSupermarket(
            supermarket,
            () => navigate("/admin?ok=true")
            // () => {
            //     setErrorMsg('Category already exists!')
            //     setName('')
            //     setFatherCategory('')
            //     setImage('')
            // }
        )
    }

    const categoryChange = (event) => {
        setSupermarket(event.target.value)
        console.log(supermarket)
    }

    return(
        <div className="background-image">
            {errorMsg && <div className="alert-product alert-danger" role="alert">{errorMsg}</div>}
            <div className="registerCategory-form">
                <h4>Remove Supermarket</h4>
                <form id="registerCategory" className="input-group-c" encType="multipart/form-data" method="POST"  onSubmit={handleSubmit}>
                    <select className="input-field-c" value={supermarket} onChange={categoryChange}>
                        <option value="Categorias">Supermercados</option>
                        {supermarkets.map(supermarket =>
                            <option className="input-field-c" value={supermarket.name} onChange={categoryChange} required>{supermarket.name}</option>
                        )}
                    </select>
                    <button type="submit" className="registerCategory-btn" >Remove Supermarket</button>
                </form>
            </div>
        </div>
    )
}

