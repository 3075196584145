import './manageOrdersPage.css'
import Navbar from "../components/Navbar";
import * as React from "react";
import {useEffect, useState} from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";
import PendingOrder from "../components/PendingOrder";

const ManageOrdersPage = () => {

    const mySystem = useMySystem()
    const auth = useAuthProvider()
    const token = auth.getAdminToken()

    const [pendinigOrders, setPendingOrders] = useState([])
    const [selectedOrder, setSelectedOrder] = useState()
    const [selectedId, setSelectedId] = useState()

    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        mySystem.getPendingOrders(token, (data) => { setPendingOrders(data) })
    }, [])

    const getEmail = (str) => {
        const parts = str.split(' ');
        return parts[0];
    }

    const getDate = (str) => {
        const parts = str.split(' ');
        return parts[1];
    }

    const getHour = (str) => {
        const parts = str.split(' ');
        return parts[2];
    }

    const handleClick = (order, id) => {
        setSelectedOrder(order)
        setSelectedId(id)
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }

    return (
        <div>
            <Navbar/>
            <div style={{ height: '5vw' }}></div>
            <div className="manage-orders-page">
                <div className="pending-orders-container">
                    <h2>Pedidos para comprar</h2>
                    {
                        pendinigOrders.filter(order => order.state === "ordered").map((order) => {
                            return (
                                <div className="pending-order">
                                    <div className="email">
                                        {getEmail(order.cartId)}
                                    </div>
                                    <div className="date">
                                        <div>
                                            {getDate(order.cartId)}
                                        </div>
                                        <div>
                                            {getHour(order.cartId)}
                                        </div>
                                    </div>
                                    <div className="order-price">
                                        {formatNumber(order.cheapestTotal)}
                                    </div>
                                    <button className="see-more" onClick={() => handleClick(order, order.cartId)}>Ver</button>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="pending-orders-container">
                    <h2>Pedidos para enviar</h2>
                    {
                        pendinigOrders.filter(order => order.state === "bought").map((order) => {
                            return (
                                <div className="pending-order">
                                    <div className="email">
                                        {getEmail(order.cartId)}
                                    </div>
                                    <div className="date">
                                        <div>
                                            {getDate(order.cartId)}
                                        </div>
                                        <div>
                                            {getHour(order.cartId)}
                                        </div>
                                    </div>
                                    <div className="order-price">
                                        {formatNumber(order.cheapestTotal)}
                                    </div>
                                    <button className="see-more" onClick={() => handleClick(order, order.cartId)}>Ver</button>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="pending-orders-container">
                    <h2>Pedidos enviados</h2>
                    {
                        pendinigOrders.filter(order => order.state === "delivered").map((order) => {
                            return (
                                <div className="pending-order">
                                    <div className="email">
                                        {getEmail(order.cartId)}
                                    </div>
                                    <div className="date">
                                        <div>
                                            {getDate(order.cartId)}
                                        </div>
                                        <div>
                                            {getHour(order.cartId)}
                                        </div>
                                    </div>
                                    <div className="order-price">
                                        {formatNumber(order.cheapestTotal)}
                                    </div>
                                    <button className="see-more" onClick={() => handleClick(order, order.cartId)}>Ver</button>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    showModal &&
                    <PendingOrder order={selectedOrder} closeModal={closeModal} cartId={selectedId} email={getEmail(selectedId)} date={getDate(selectedId)}/>
                }
            </div>
        </div>
    )
}

export default ManageOrdersPage