import React, {useEffect, useState} from "react";
import {useAuthProvider} from "../auth/auth";
import {useMySystem} from "../service/mySystem";
import "./shoppingCart.css"
import {AmountButton} from "./AmountButton";
import Loader from "./Loader";
import {BsCart2} from "react-icons/bs";
import PricesSorter from "./PricesSorter";

const ShoppingCart = (props) => {

    const cartItems = props.cartItems;
    const [products, setProducts] = useState([]);
    const mySystem = useMySystem()
    const auth = useAuthProvider()
    const token = auth.getToken()

    const isLoading = props.isLoading;

    useEffect(() => {

    }, [props.aux])

    useEffect(() => {
        // Transformar la lista de CartProducts en processedList
        const productsAux = cartItems.map((cartProduct) => ({
            name: cartProduct.product.name,
            category: cartProduct.product.category,
            ean: cartProduct.product.ean,

            jumboName: cartProduct.product.jumboName,
            CarrefourName: cartProduct.product.CarrefourName,
            CotoName: cartProduct.product.CotoName,

            brand: cartProduct.product.brand,
            quantity: cartProduct.product.quantity,
            unityOfMeasure: cartProduct.product.unityOfMeasure,

            jumboPrice: cartProduct.product.jumboPrice,
            carrefourPrice: cartProduct.product.carrefourPrice,
            cotoPrice: cartProduct.product.cotoPrice,

            jumboStock: cartProduct.product.jumboStock,
            carrefourStock: cartProduct.product.carrefourStock,
            cotoStock: cartProduct.product.cotoStock,

            jumboImage: cartProduct.product.jumboImage,
            carrefourImage: cartProduct.product.carrefourImage,
            cotoImage: cartProduct.product.cotoImage,

            jumboLink: cartProduct.product.jumboLink,
            carrefourLink: cartProduct.product.carrefourLink,
            cotoLink: cartProduct.product.cotoLink,

            averagePrice: cartProduct.product.averagePrice,
            highestPrice: cartProduct.product.highestPrice,
            lowestPrice: cartProduct.product.lowestPrice,

            amount: cartProduct.amount,
        }));
        setProducts(productsAux);
    }, [cartItems]);

    const sortProducts = () => {
        products.sort(function(a, b) {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }

    return(
        <div>
            <div className="producto-carrito">
                {sortProducts()}
                {
                    isLoading ? (
                        <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <Loader width={'10vw'}/>
                        </div>
                    ) : (
                        <div>
                            {
                                cartItems.length === 0 &&
                                <div className="empty-cart">
                                    <BsCart2  color="darkred" size="13%">
                                    </BsCart2>
                                    El carrito está vacío
                                </div>
                            }
                            {products.map(item =>
                                <div key={item} className="cart-item-card">
                                    {/*<img src={process.env.P UBLIC_URL + '/uploads/' + item.category + '/' + item.name + '.png'} alt={item.name}/>*/}
                                    {item.jumboImage !== 'jumboImage' ? (
                                        <img src={item.jumboImage} alt={item.name}/>
                                    ) : item.carrefourImage !== 'carrefourImage' ? (
                                        <img src={item.carrefourImage} alt={item.name}/>
                                    ) : item.cotoImage !== 'cotoImage' && (
                                        <img src={item.cotoImage} alt={item.name}/>
                                    )
                                    }
                                    <div className="product-info-container" >
                                        <p className="cart-item-name">
                                            {item.name}
                                        </p>
                                        <PricesSorter item={item} />
                                    </div>
                                    <div className="amount-buttons">
                                        <AmountButton
                                            productId={item.ean}
                                            aux={props.aux}
                                            setAux={props.setAux}
                                            amount={item.amount}

                                            setSmarketPrice={props.setSmarketPrice}
                                            setExpensivePrice={props.setExpensivePrice}
                                            setJumboPrice={props.setJumboPrice}
                                            setCarrefourPrice={props.setCarrefourPrice}
                                            setCotoPrice={props.setCotoPrice}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ShoppingCart