import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useMySystem} from "../service/mySystem";
import './supermarketRegister.css'
import {useAuthProvider} from "../auth/auth";

export const SupermarketRegisterPage = () => {
    const [name, setName] = useState('')
    // const [amountOfAddresses, setamountOfAddresses] = useState()
    // const [selectedStreet, setSelectedStreet] = useState('')
    // const [selectedNumber, setSelectedNumber] = useState('')
    // const [selectedCity, setSelectedCity] = useState('')
    // const [streets, setStreets] = useState([])
    // const [numbers, setNumbers] = useState([])
    // const [cities, setCities] = useState([])
    const [street, setStreet] = useState()
    const [number, setNumber] = useState()
    const [city, setCity] = useState()
    const [errorMsg, setErrorMsg] = useState(undefined)
    const navigate = useNavigate();
    const mySystem = useMySystem();
    const auth = useAuthProvider()
    const token = auth.getAdminToken();

    const handleSubmit = async e => {
        e.preventDefault();

        registerSupermarket({
            name: name,
            street: street,
            number: number,
            city: city,
        })
    }
    
    const registerSupermarket = (supermarket) => {
        mySystem.registerSupermarket(
            supermarket,
            () => navigate("/admin?ok=true"),
            () => {
                setErrorMsg('Supermarket already exists!')
                setName('')
            }
        )
    }

    const nameChange = (event) => {
        setName(event.target.value)
    }

    // const amountOfAddressesChange = (event) => {
    //     setamountOfAddresses(event.target.value)
    // }

    const streetChange = (event) => {
        setStreet(event.target.value)
        // streets.push.apply(selectedStreet)
    }

    const numberChange = (event) => {
        setNumber(event.target.value)
        // numbers.push.apply(selectedNumber)
    }

    const cityChange = (event) => {
        setCity(event.target.value)
        // cities.push.apply(selectedCity)
    }

    // function registerAddresses(){
    //     let amounts = [];
    //     for (let i = 1; i <= amountOfAddresses; i++) {
    //         amounts.push(i);
    //     }
    //     return <div>
    //         {amounts.map(amount =>
    //             <div>
    //                 <div className="direction"> Dirección {amount}</div>
    //                 <label>
    //                     <input type="text" className="input-field" placeholder="Calle" value={streets} onChange={streetChange} required/>
    //                 </label>
    //                 <label>
    //                     <input type="text" className="input-field" placeholder="Número" value={numbers} onChange={numberChange} required/>
    //                 </label>
    //                 <label>
    //                     <input type="text" className="input-field" placeholder="Ciudad" value={cities} onChange={cityChange} required/>
    //                 </label>
    //             </div>
    //         )}
    //     </div>
    // }

    return(
        <div className="background-image">
            {errorMsg && <div className="alert-product alert-danger" role="alert">{errorMsg}</div>}
            <div className="registerSupermarket-form">
                <div className="top">
                    <h4>Register Supermarket</h4>
                </div>
                <form id="registerSupermarket" className="input-group-s" encType="multipart/form-data" method="POST"  onSubmit={handleSubmit}>
                        <input type="text" className="input-field-s" placeholder="Name" value={name} onChange={nameChange} required/>
                    {/*<label>*/}
                    {/*    <input type="text" className="input-field" placeholder="Amount of addresses" value={amountOfAddresses} onChange={amountOfAddressesChange} required/>*/}
                    {/*</label>*/}
                    {/*{registerAddresses()}*/}
                    <div className="direction"> Dirección </div>
                             <input type="text" className="input-field-s" placeholder="Calle" value={street} onChange={streetChange} required/>
                             <input type="text" className="input-field-s" placeholder="Número" value={number} onChange={numberChange} required/>
                             <input type="text" className="input-field-s" placeholder="Ciudad" value={city} onChange={cityChange} required/>
                    <button type="submit" className="registerSupermarket-btn" >Register Supermarket</button>
                </form>
            </div>
        </div>
    )
}

