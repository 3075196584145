import styled from 'styled-components';
import {useEffect, useState} from "react";
import {useAuthProvider} from "../auth/auth";
import {useMySystem} from "../service/mySystem";

const ProgressBar = ( props ) => {

    const totalJumbo = 4000
    const totalCarrefour = 4000
    const totalCoto = 4000

    const [cart, setCart] = useState()
    const [currentJumbo, setCurrentJumbo] = useState(0)
    const [currentCarrefour, setCurrentCarrefour] = useState(0)
    const [currentCoto, setCurrentCoto] = useState(0)

    const progressJumbo = (currentJumbo / totalJumbo) * 100;
    const progressCarrefour = (currentCarrefour / totalCarrefour) * 100;
    const progressCoto = (currentCoto / totalCoto) * 100;

    const mySystem = useMySystem()
    const auth = useAuthProvider()
    const token = auth.getToken()

    useEffect(() => {
        mySystem.getCart(token, (cart) => {
                setCart(cart)
                setCurrentJumbo(cart.jumboDividedTotal)
                setCurrentCarrefour(cart.carrefourDividedTotal)
                setCurrentCoto(cart.veaDividedTotal)
            }
        )
    }, [props.aux])

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(number);
    }

    return (
        <ProgressBarContainer>
            <Title>Mínimos por supermercado</Title>
            {currentJumbo < totalJumbo ? (
                    <BarContainer>
                        <SuperName>
                            Jumbo:
                        </SuperName>
                        <ProgressContainer>
                            <ProgressFill progress={progressJumbo}></ProgressFill>
                            <Amount>
                                {formatNumber(currentJumbo)} / {totalJumbo}
                            </Amount>
                        </ProgressContainer>
                    </BarContainer>
                ) : (
                    <BarContainer>
                        <SuperName>
                            Jumbo:
                        </SuperName>
                            <ProgressCompleted>Superado</ProgressCompleted>
                    </BarContainer>
                )
            }
            {currentCarrefour < totalCarrefour ? (
                    <BarContainer>
                        <SuperName>
                            Carrefour:
                        </SuperName>
                        <ProgressContainer>
                            <ProgressFill progress={progressCarrefour}></ProgressFill>
                            <Amount>
                                {formatNumber(currentCarrefour)} / {totalCarrefour}
                            </Amount>
                        </ProgressContainer>
                    </BarContainer>
                ) : (
                    <BarContainer>
                        <SuperName>
                            Carrefour:
                        </SuperName>
                        <ProgressCompleted>Superado</ProgressCompleted>
                    </BarContainer>
                )
            }
            {currentCoto < totalCoto ? (
                    <BarContainer>
                        <SuperName>
                            Coto:
                        </SuperName>
                        <ProgressContainer>
                            <ProgressFill progress={progressCoto}></ProgressFill>
                            <Amount>
                                {formatNumber(currentCoto)} / {totalCoto}
                            </Amount>
                        </ProgressContainer>
                    </BarContainer>
                ) : (
                    <BarContainer>
                        <SuperName>
                            Coto:
                        </SuperName>
                        <ProgressCompleted>Superado</ProgressCompleted>
                    </BarContainer>
                )
            }
        </ProgressBarContainer>
    );
}

export default ProgressBar;

const ProgressBarContainer = styled.div`
  background-color: #fff;
  border-radius: 0.4vw;
  box-shadow: 0 0 0.1vw 0.1vw rgba(0, 0, 0, 0.2);
  position: fixed;
  margin-top: 17vw;
  margin-left: 0;
  padding: 1vw 1.5vw 1.5vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  z-index: 100;
`

const Title = styled.div`
  font-size: 1.2vw;
  font-weight: 500;
  margin-bottom: 1vw;
`

const BarContainer = styled.div`
  display: flex;
  align-items: center;
`

const SuperName = styled.div`
  width: 6.5vw;
  font-size: 1.1vw;
`

const ProgressContainer = styled.div`
  width: 15vw;
  overflow: hidden;
  background-color: #800b0b;
  border-radius: 0.2vw;
  display: flex;
  justify-content: start;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 1.1vw;
`

const ProgressFill = styled.div`
  width: ${(props) => props.progress}%;
  height: 2vw;
  background-color: #ec0202;
  border-radius: 0.2vw;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bold;
`

const Amount = styled.div`
  width: 14.5vw;
  position: absolute;
  display: flex;
  justify-content: end;
  font-size: 1.1vw;
`

const ProgressCompleted = styled.div`
  width: 15vw;
  height: 2vw;
  overflow: hidden;
  background-color: #ec0202;
  border-radius: 0.2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 1.1vw;
`