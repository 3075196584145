import './deliveryData.css'
import {useEffect, useState} from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";
import * as React from "react";

const PaymentDetails = (props) => {

    const pricesData = props.pricesData

    const jumboPrice = pricesData.jumboPrice
    const carrefourPrice = pricesData.carrefourPrice
    const cotoPrice = pricesData.cotoPrice
    const smarketPrice = pricesData.smarketPrice
    const setJumboPrice = pricesData.setJumboPrice
    const setCarrefourPrice = pricesData.setCarrefourPrice
    const setCotoPrice = pricesData.setCotoPrice
    const setSmarketPrice = pricesData.setSmarketPrice

    const paymentData = props.paymentData

    const cardNumber = paymentData.cardNumber
    const expiryDate = paymentData.expiryDate
    const cardHolder = paymentData.cardHolder
    const cvv = paymentData.cvv
    const setCardNumber = paymentData.setCardNumber
    const setExpiryDate = paymentData.setExpiryDate
    const setCardHolder = paymentData.setCardHolder
    const setCvv = paymentData.setCvv

    const handleClick = (event) => {
        event.preventDefault();
        // Aquí puedes realizar la lógica para procesar la información de la tarjeta de crédito
        // Por ejemplo, puedes enviar los datos a un servidor para realizar el pago o validar la tarjeta.
        props.setAccordionOpen(false)
        props.setPaymentDone(true)
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }

    return(
        <div className='delivery-container'>
            <div className='delivery-title'>
                <h2>Ingrese los datos de su tarjeta</h2>
            </div>
            {/*<form id="delivery" className="delivery-form" onSubmit={handleClick}>*/}
            {/*    <div className='delivery-details'>*/}
            {/*        <div className='delivery-fields'>*/}
            {/*            <input type="text" placeholder="Número de la tarjeta" value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} required />*/}
            {/*            <input type="text" placeholder="Fecha de vencimiento (MM/AA)" style={{ flex: 60 }} value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} required />*/}
            {/*        </div>*/}
            {/*        <div className='delivery-fields'>*/}
            {/*            <input type="text" placeholder="Nombre del titular" value={cardHolder} onChange={(e) => setCardHolder(e.target.value)} required />*/}
            {/*            <input type="text" placeholder="CVV" style={{ flex: 60 }} value={cvv} onChange={(e) => setCvv(e.target.value)} required />*/}
            {/*        </div>*/}
            {/*        <div className='delivery-price' >*/}
            {/*            Monto a pagar:*/}
            {/*            <div style={{ fontWeight: "bold", marginLeft: "0.5vw" }}>*/}
            {/*                ${smarketPrice}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <button type="submit" className='delivery-button' >*/}
            {/*        Continuar*/}
            {/*    </button>*/}
            {/*</form>*/}
            <div className="mp-info">
                <div className='delivery-price' >
                    Monto a pagar:
                    <div style={{ fontWeight: "bold", marginLeft: "0.5vw" }}>
                        {formatNumber(smarketPrice)}
                    </div>
                </div>

                <h2>Para pagar ingrese en mercado pago y transfiera el monto a pagar a la siguiente cuenta:</h2>

                <p>Alias: smarket.lat</p>

                <p>CVU: 0000003100005400131972</p>

                <p>Cuando se acredite el pago le llegará un mail confirmando la compra</p>

                <button onClick={handleClick} className='delivery-button' >
                    Continuar
                </button>
            </div>
        </div>
    )
}

export default PaymentDetails