import React, { useState } from 'react';
import './Accordion.css'; // Estilo para el acordeón

const Accordion = (props) => {
    const setIsOpen = props.setIsOpen;

    const toggleAccordion = () => {
        setIsOpen(!props.isOpen);
    };

    return (
        <div className={`accordion ${props.isOpen ? 'open' : 'closed'}`}>
            <div className="accordion-header" onClick={toggleAccordion}>
                <h2 className="accordion-title">{props.title}</h2>
                <span className={`icon ${props.isOpen ? 'open' : 'closed'}`}>&#9660;</span>
            </div>
            <div className="accordion-content">
                {props.children}
                <div style={{height: '4vw'}}></div>
            </div>
        </div>
    );
};

export default Accordion;