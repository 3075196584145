import React from 'react'
import './popup.css'

function Popup(props){
    return (props.product) && (
        <div className="popup">
            <div className="popup-inner">
                <div>{props.children}</div>
            </div>
        </div>
    )
}

export default Popup