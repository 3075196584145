import {useRef} from "react";
import Loader from "./Loader";
import {IoIosArrowBack} from "react-icons/io";
import * as React from "react";
import {useNavigate} from "react-router";

const HomeCategory = ( props ) => {

    const title = props.title;
    const subcategories = props.subcategories;
    const subcategoriesAvailable = props.subcategoriesAvailable;
    const containerRef = useRef(null);

    const isLoading = props.isLoading;
    const navigate = useNavigate();

    const scrollRight = ( containerRef ) => {
        if (containerRef.current) {
            const windowWidth = window.innerWidth;
            const scrollAmount = (windowWidth * 50) / 100;

            containerRef.current.scrollTo({
                left: containerRef.current.scrollLeft + scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    const scrollLeft = ( containerRef ) => {
        if (containerRef.current) {
            const windowWidth = window.innerWidth;
            const scrollAmount = (windowWidth * 50) / 100;

            containerRef.current.scrollTo({
                left: containerRef.current.scrollLeft - scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    const formatName = (name) => {
        if (name === "isotonicas") {
            return "Isotónicas";
        }
        // for names with the following format: "gaseosas-light" -> "Gaseosas light"
        if (name.includes("-")) {
            const words = name.split("-");
            const newName = words.map(word => word.charAt(0) + word.slice(1)).join(" ");
            return newName.charAt(0).toUpperCase() + newName.slice(1);
        }
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    return(
        <div>
            <h2 className="titulo">{title}</h2>
            {
                isLoading ? (
                    <div style={{ display: 'flex', width: '100%', height: '13vw', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader width='4vw'/>
                    </div>
                ) : (
                    <div className="category-container">
                        <button className="scroll-arrows-buttons" onClick={ () => scrollLeft(containerRef)} >
                            <IoIosArrowBack size={"1.3vw"} />
                        </button>
                        <div className="categoria" ref={containerRef}>
                            {subcategories.map(subcategoria =>
                                <button
                                    onClick={
                                        subcategoriesAvailable(subcategoria) ?
                                            () => {}
                                            :
                                            () => navigate("/product/"+subcategoria.name.toLowerCase())
                                    }
                                >
                                    <div className={subcategoriesAvailable(subcategoria) ? "card-disabled" : "card"}>
                                        <div className="category-image-container">
                                            <img src={subcategoria.logo} alt="img gaseosa"/>
                                        </div>
                                        <h3 className="nombre">{formatName(subcategoria.name)}</h3>
                                    </div>
                                </button>
                            )}
                            <div style={{ width: "2vw" }} />
                        </div>
                        <button className="scroll-arrows-buttons" onClick={() => scrollRight(containerRef)} >
                            <IoIosArrowBack style={{ rotate: "180deg" }} size={"1.3vw"} />
                        </button>
                    </div>
                )
            }
        </div>
    )
}

export default HomeCategory
