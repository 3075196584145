import "./pedido.css"
import * as React from "react";
import logoJumbo from "../pages/images/Jumbo 2.png";
import logoCarrefour from "../pages/images/Carrefour.png";
import logoCoto from "../pages/images/Coto 2.png";
import logoSmarket from "../pages/images/Logo Red 1.png"
import logoGeneral from "../pages/images/General.png"
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";
import {useNavigate} from "react-router-dom";
import {AiFillInfoCircle} from "react-icons/ai";
import Modal from "./Modal";
import Loader from "./Loader";


export const Pedido = (props) => {

    const mySystem = useMySystem();
    const auth = useAuthProvider();
    const token = auth.getToken();
    const [supermarkets, setSupermarkets] = useState([]);
    // const [url, setUrl] = useState('');
    const prices = props.prices
    const jumboPrice = prices[0].price
    const carrefourPrice = prices[1].price
    const cotoPrice = prices[2].price

    const minimumPrice = 4000

    const aux = props.aux
    const setAux = props.setAux
    const navigate = useNavigate()

    const setSmarketPrice = props.setSmarketPrice
    const setExpensivePrice = props.setExpensivePrice
    const setJumboPrice = props.setJumboPrice
    const setCarrefourPrice = props.setCarrefourPrice
    const setCotoPrice = props.setCotoPrice

    const cartItems = props.cartItems
    const setCartItems = props.setCartItems

    const [showInfoSmarket, setShowInfoSmarket] = useState(false);
    const [showInfoGeneral, setShowInfoGeneral] = useState(false);
    const [showMessage, setShowMessage] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        prices.sort((a, b) => { //no se por qué no funciona
            if(a.price === b.price) {
                return 0;
            }
            if(a.price < b.price) {
                return -1;
            }
            return 1;
        })
        // mySystem.listSupermarkets(userEmail, setIsLoading, (supermarkets) => setSupermarkets(supermarkets))
    }, [])

    function emptyCart() {
        mySystem.removeItems(
            token,
            () => {
                mySystem.getSmarketPrice(token, (smarketPrice) => setSmarketPrice(smarketPrice))
                mySystem.getExpensivePrice(token, (expensivePrice) => setExpensivePrice(expensivePrice))
                mySystem.getJumboPrice(token, (jumboPrice) => setJumboPrice(jumboPrice))
                mySystem.getCarrefourPrice(token, (carrefourPrice) => setCarrefourPrice(carrefourPrice))
                mySystem.getVeaPrice(token, (cotoPrice) => setCotoPrice(cotoPrice))

                mySystem.showCart(setIsLoading, token, (cartItems) => setCartItems(cartItems));
            },
            () => {
            }
        );
        setAux(!aux)
    }


    function buyCart() {
        // mySystem.buyCart(userEmail, (url) => window.location.href = url);
        if (jumboPrice > minimumPrice || carrefourPrice > minimumPrice || cotoPrice > minimumPrice) {
            navigate("/payment");
        } else {
            setShowMessage(true)
            setTimeout(() => {
                setShowMessage(false)
            }, 3000)
        }
    }

    function openLinks() {
        // open in new tabs the links of all the products in the cart
        cartItems.forEach((item) => {
            if (item.product.lowestPrice === item.product.jumboPrice) {
                window.open(item.product.jumboLink, '_blank');
            } else if (item.product.lowestPrice === item.product.carrefourPrice) {
                window.open(item.product.carrefourLink, '_blank');
            } else if (item.product.lowestPrice === item.product.cotoPrice) {
                window.open(item.product.cotoLink, '_blank');
            }
        })
    }


    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }

    const handleInfoSmarketIn = () => {
        setShowInfoSmarket(true);
    };

    const handleInfoSmarketOut = () => {
        setShowInfoSmarket(false);
    };

    const handleInfoGeneralIn = () => {
        setShowInfoGeneral(true);
    };

    const handleInfoGeneralOut = () => {
        setShowInfoGeneral(false);
    };

    return(

        <div className="checkout">
            <div className="supers">
                {
                    isLoading ? (
                        <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <Loader />
                        </div>
                    ) : (
                        <div>
                            <div className="precio-smarket-container">
                                <img src={logoSmarket} alt="img-smarket"/>
                                <div className="precio-ahorro-container">
                                    <div className="precio-smarket">
                                        Total:
                                        <div className="numero-smarket">
                                            {formatNumber(props.smarketPrice)}
                                        </div>
                                    </div>
                                    <div className="ahorro-smarket">
                                        {props.smarketPrice > 0 && (
                                            <div className="ahorro-container">
                                                <div className="ahorro-texto">
                                                    Ahorrás: &nbsp;&nbsp;
                                                </div>
                                                <div className="ahorro">
                                                    {/*-%{100-((props.smarketPrice*100)/props.expensivePrice).toFixed(0)}*/}
                                                    {formatNumber((props.expensivePrice - props.smarketPrice).toFixed(0))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <AiFillInfoCircle
                                    color={'rgb(200, 200, 210)'}
                                    onMouseOver={handleInfoSmarketIn}
                                    onMouseOut={handleInfoSmarketOut}
                                    style={{ marginRight: '4%' }}
                                    size={'1.3vw'}
                                />
                            </div>
                            <div className="precio-super-container">
                                <img src={logoGeneral} alt="img-super"/>
                                <div className="precio-super">
                                    Total:
                                    <div className="numero-super">
                                        {formatNumber(props.expensivePrice)}
                                    </div>
                                </div>
                                <AiFillInfoCircle
                                    color={'rgb(200, 200, 210)'}
                                    onMouseOver={handleInfoGeneralIn}
                                    onMouseOut={handleInfoGeneralOut}
                                    style={{ marginRight: '4%' }}
                                    size={'1.3vw'}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
            {showInfoSmarket &&
                <div className="info">
                    Toma el precio más barato de todos los productos seleccionados
                </div>
            }
            {showInfoGeneral &&
                <div className="info">
                    Toma el precio más alto de todos los productos seleccionados.
                </div>
            }
            {showMessage &&
                <div className="info">
                    Tenes que llegar al mínimo de compra de ${minimumPrice} en algun supermercado
                </div>
            }
            <button className="show-more-button" onClick={() => {props.handleOpenModal()}}>Ver más</button>

            <button className="finalizar-compra" onClick={() => {props.handleOpenModalBuyCart()}} >
                Finalizar compra
            </button>
            <button className="cancelar-compra" onClick={emptyCart}>
                Cancelar
            </button>
        </div>
        )
}