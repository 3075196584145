import {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import * as React from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";
import DeliveryData from "../components/DeliveryData";
import PaymentDetails from "../components/PaymentDetails";
import ContactInfo from "../components/ContactInfo";
import Accordion from "../components/Accordion";
import confirmedIcon from "./images/icons/confirm.png"
import GoBackButton from "../components/GoBackButton";
import {FaArrowLeft} from "react-icons/fa";
import {useNavigate} from "react-router";

const PaymentDetailsPage = () => {

    const [jumboPrice, setJumboPrice] = useState()
    const [carrefourPrice, setCarrefourPrice] = useState()
    const [cotoPrice, setCotoPrice] = useState()
    const [smarketPrice, setSmarketPrice] = useState()

    const pricesData = {
        jumboPrice: jumboPrice,
        carrefourPrice: carrefourPrice,
        cotoPrice: cotoPrice,
        smarketPrice: smarketPrice,
        setJumboPrice: setJumboPrice,
        setCarrefourPrice: setCarrefourPrice,
        setCotoPrice: setCotoPrice,
        setSmarketPrice: setSmarketPrice
    }

    const mySystem = useMySystem()
    const auth = useAuthProvider()
    const token = auth.getToken()
    const navigate = useNavigate();

    const [cardNumber, setCardNumber] = useState('');
    const [cardHolder, setCardHolder] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');

    const paymentData = {
        cardNumber: cardNumber,
        cardHolder: cardHolder,
        expiryDate: expiryDate,
        cvv: cvv,
        setCardNumber: setCardNumber,
        setCardHolder: setCardHolder,
        setExpiryDate: setExpiryDate,
        setCvv: setCvv
    }

    const [deliveryType, setDeliveryType] = useState('')
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [street, setStreet] = useState('')
    const [number, setNumber] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [aditionalNotes, setAditionalNotes] = useState('')

    const deliveryData = {
        deliveryType: deliveryType,
        province: province,
        city: city,
        street: street,
        number: number,
        postalCode: postalCode,
        aditionalNotes: aditionalNotes,
        setDeliveryType: setDeliveryType,
        setProvince: setProvince,
        setCity: setCity,
        setStreet: setStreet,
        setNumber: setNumber,
        setPostalCode: setPostalCode,
        setAditionalNotes: setAditionalNotes
    }

    const [name, setName] = useState('')
    const [lastname, setLastname] = useState('')
    const [cellphoneNumber, setCellphoneNumber] = useState('')

    const contactInfo = {
        name: name,
        lastname: lastname,
        cellphoneNumber: cellphoneNumber,
        setName: setName,
        setLastname: setLastname,
        setCellphoneNumber: setCellphoneNumber
    }

    const [isOpenDelivery, setIsOpenDelivery] = useState(true);
    const [isOpenPayment, setIsOpenPayment] = useState(false);
    const [isOpenContact, setIsOpenContact] = useState(false);

    const [deliveryDone, setDeliveryDone] = useState(false);
    const [paymentDone, setPaymentDone] = useState(false);
    const [contactDone, setContactDone] = useState(false);

    const [orderDone, setOrderDone] = useState(false);

    useEffect(() => {
        mySystem.getJumboPrice(token, (jumboPrice) => setJumboPrice(jumboPrice))
        mySystem.getCarrefourPrice(token, (carrefourPrice) => setCarrefourPrice(carrefourPrice))
        mySystem.getVeaPrice(token, (cotoPrice) => setCotoPrice(cotoPrice))
        mySystem.getSmarketPrice(token, (smarketPrice) => setSmarketPrice(smarketPrice))

        mySystem.getLastDeliveryData(token, (data) => {
            setDeliveryType(data.type)
            setProvince(data.province)
            setCity(data.city)
            setStreet(data.street)
            setNumber(data.number)
            setPostalCode(data.postalCode)
            setAditionalNotes(data.notes)
        })
    }, [])

    const handleDelivery = () => {
        const deliveryData = {
            deliveryType: deliveryType,
            province: province,
            city: city,
            street: street,
            number: number,
            postalCode: postalCode,
            notes: aditionalNotes,
        }
        mySystem.setDeliveryLocation(token, deliveryData)
    }

    const handlePayment = () => {
        const paymentData = {
            cardNumber: cardNumber,
            cardHolder: cardHolder,
            expiryDate: expiryDate,
            cvv: cvv,
        }
        // mySystem.setPaymentDetails(token, paymentData)
    }

    const handleContact = () => {
        const deliveryData = {
            province: name,
            city: lastname,
            number: number,
        }
        // mySystem.setContactInfo(token, deliveryData)
    }

    const handleClick = () => {
        // confirmar pedido
        // handleDelivery()
        handlePayment()
        handleContact()
        mySystem.buyCart(token)
        setOrderDone(true)
    };

    return (
        <div className="checkout-container" style={{overflowX: "hidden", overflowY: "hidden"}}>
            <Navbar/>
            <div style={{ height: '3vw' }}></div>
            {
                orderDone ? (
                    <div className="order-done-container">
                        <h2>
                            Pedido confirmado
                        </h2>
                        <img src={confirmedIcon} alt="confirmed icon"/>
                    </div>
                ) : (
                    <div style={{ height: '92vh', overflow: 'scroll', overflowX: "hidden", scrollSnapType: 'y mandatory' }}>
                        <div style={{ display: 'flex', width: '98%', marginTop: '2vw' }}>
                            <button style={{
                                margin: '2.3vw 3% 0 10%',
                                flex: '6',
                                backgroundColor: 'rgb(245, 245, 253)' ,
                                height: '2.7vw',
                                boxShadow: '0 0 0.15vw 0.07vw rgba(0, 0, 0, 0.2)',
                                borderRadius: '0.5vw',
                                color: 'darkred',
                                fontSize: '1.3vw',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                                    onClick={() => navigate(-1)}
                            >
                                <FaArrowLeft />
                            </button>
                            <div style={{ flex: '80', marginLeft: '2%' }}>
                                <Accordion title="Envío" isOpen={isOpenDelivery} setIsOpen={setIsOpenDelivery}>
                                    <DeliveryData
                                        sendRequest={handleDelivery}
                                        deliveryData={deliveryData}
                                        setAccordionOpen={setIsOpenDelivery}
                                        setNextAccordionOpen={setIsOpenContact}
                                        setDeliveryDone={setDeliveryDone}/>
                                </Accordion >
                                <Accordion title="Contacto" isOpen={isOpenContact} setIsOpen={setIsOpenContact}>
                                    <ContactInfo
                                        contactInfo={contactInfo}
                                        setAccordionOpen={setIsOpenContact}
                                        setNextAccordionOpen={setIsOpenPayment}
                                        setContactDone={setContactDone}/>
                                </Accordion >
                                <Accordion title="Pago" isOpen={isOpenPayment} setIsOpen={setIsOpenPayment}>
                                    <PaymentDetails
                                        pricesData={pricesData}
                                        paymentData={paymentData}
                                        setAccordionOpen={setIsOpenPayment}
                                        setPaymentDone={setPaymentDone}/>
                                </Accordion >
                            </div>
                        </div>
                        {
                            deliveryDone && paymentDone && contactDone && (
                                <button className='delivery-button' onClick={() => {handleClick()}} style={{ width: '30%', margin: '4vw 0 5vw 35%'}} >
                                    Confirmar Pedido
                                </button>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default PaymentDetailsPage