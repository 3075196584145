import React, {useEffect} from "react";
import { useState } from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";
import {useNavigate} from "react-router";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai";
import {TbTrash} from "react-icons/tb";
import Loader from "./Loader";
import styled from "styled-components";

export const AmountButton = (props) => {

    const mySystem = useMySystem()
    const [errorMsg, setErrorMsg] = useState(undefined)
    const productId = props.productId
    const auth = useAuthProvider()
    const token = auth.getToken()
    const navigate = useNavigate()
    // const [amount, setAmount] = useState(0)
    const [amount, setAmount] = useState(props.amount)
    const aux = props.aux
    const setAux = props.setAux

    const setSmarketPrice = props.setSmarketPrice
    const setExpensivePrice = props.setExpensivePrice
    const setJumboPrice = props.setJumboPrice
    const setCarrefourPrice = props.setCarrefourPrice
    const setCotoPrice = props.setCotoPrice

    const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     mySystem.userEmail(token, (email) => setEmail(email));
    // }, [aux])

    // useEffect(() => {
        // mySystem.getAmount(token, setIsLoading, {productId: productId}, (amount) => setAmount(amount))
    // }, [aux])

    function AddToCart(newAmount){
        AddProductToCart({
            productId: productId,
            amount: newAmount,
        })
        setAux(!aux)
    }

    function Reduce(){
        if (amount >= 1) {
            AddProductToCart({
                productId: productId,
                amount: amount-1,
            })
        }
        setAux(!aux)
        if (amount === 1) {
            window.location.reload();
        }
    }

    function RemoveFromCart(){
        if (amount > 0) {
            AddProductToCart({
                productId: productId,
                amount: 0,
            })
        }
        window.location.reload()
        // setAux(!aux)
    }

    const AddProductToCart = (productIdAndAmount) => {
        mySystem.addProducts(
            token,
            productIdAndAmount,
            () => {
                setAmount(productIdAndAmount.amount)
                mySystem.getSmarketPrice(token, (smarketPrice) => setSmarketPrice(smarketPrice))
                mySystem.getExpensivePrice(token, (expensivePrice) => setExpensivePrice(expensivePrice))
                mySystem.getJumboPrice(token, (jumboPrice) => setJumboPrice(jumboPrice))
                mySystem.getCarrefourPrice(token, (carrefourPrice) => setCarrefourPrice(carrefourPrice))
                mySystem.getVeaPrice(token, (cotoPrice) => setCotoPrice(cotoPrice))
            },
            () => {
                setErrorMsg('El producto seleccionado no posee stock')
            }
        )
    }

    const changeAmount = (event) => {
        const newValue = event.target.value;
        if (newValue >= 1 && newValue <= 35) {
            AddToCart(newValue)
        }
    }

    return(
        <StyledAmountButton>
            {/*{*/}
            {/*     isLoading ? (*/}
            {/*         <div style={{ display: 'flex', width: '100%', height: '2vw', justifyContent: 'center', alignItems: 'center' }}>*/}
            {/*             <Loader width='2vw'/>*/}
            {/*         </div>*/}
            {/*     ) : (*/}
            <div className={"agregado2"}>
                {
                    amount > 1 ? (
                        <AiOutlineMinus  className="change-amount-button" onClick={Reduce}/>
                    ):(
                        <TbTrash className="change-amount-button" onClick={Reduce}/>
                    )
                }
                <input className={"amount2"} type="number" value={amount} onChange={changeAmount}/>
                {/*<div className={"amount2"}>*/}
                {/*    {amount}*/}
                {/*</div>*/}
                <AiOutlinePlus className="change-amount-button" onClick={() => AddToCart(Number(amount)+1)}/>

            </div>
            {/*    )*/}
            {/*}*/}
        </StyledAmountButton>
    )
}


const StyledAmountButton = styled.div`
  display: inline-flex;
  
  .btn-sumar2 {
    background-color: darkred;
    margin-top: 0.2vw;
    height: 1.6vw;
    width: 1.6vw;
    margin-left: 1.5vw;
    font-size: 15px;
    border-radius: 0.5vw;
    color: white;
    cursor: pointer;
  }
  
  .btn-restar2 {
    background-color: darkred;
    margin-top: 0.2vw;
    height: 1.6vw;
    width: 1.6vw;
    margin-left: 0.3vw;
    font-size: 1.3vw;
    border-radius: 0.5vw;
    color: white;
    cursor: pointer;
  }
  
  .agregado2 {
    display: inline-flex;
    align-items: center;
    /*background-color: rgb(217, 217, 230);*/
    /*margin-top: -4vw;*/
    /*margin-left: 35vw;*/
    width: 11vw;
    height: 2vw;
    padding: 0.1vw;
    border-radius: 0.7vw;
    font-size: 1.5vw;
    color: black;
    margin-left: 15%;
  }
  
  .amount2 {
    margin: 0.3vw 1vw;
    width: 45%;
    font-size: 1.3vw;
    padding: 0.2vw 0;
    border: none;
    text-align: center;
    box-shadow: 0 0 0.2vw 0.08vw rgba(0,0,0,0.2);
    border-radius: 0.4vw;
  }
  
  .remover {
    background-color: rgb(217, 217, 230);
    /*margin-top: -4vw;*/
    height: 2vw;
    width: 2vw;
    margin-left: 0.8vw;
    font-size: 1.5vw;
    border-radius: 0.3vw;
    color: white;
    cursor: pointer;
  }
  
  .change-amount-button {
    color: darkred;
    /*background-color: darkred;*/
    border-radius: 0.4vw;
    /*border-top: 0.2vw solid darkred;*/
    /*border-bottom: 0.2vw solid darkred;*/
    /*box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
    cursor: pointer;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  @media(max-device-width: 700px){
    display: inline-flex;

    .btn-sumar2 {
      background-color: darkred;
      margin-top: 0.2vw;
      height: 7vw;
      width: 7vw;
      margin-left: 1.5vw;
      font-size: 15px;
      border-radius: 0.5vw;
      color: white;
      cursor: pointer;
    }

    .btn-restar2 {
      background-color: darkred;
      margin-top: 0.2vw;
      height: 7vw;
      width: 7vw;
      margin-left: 0.3vw;
      font-size: 15px;
      border-radius: 0.5vw;
      color: white;
      cursor: pointer;
    }

    .agregado2 {
      display: inline-flex;
      align-items: center;
      /*background-color: rgb(217, 217, 230);*/
      /*margin-top: -4vw;*/
      /*margin-left: 35vw;*/
      width: 37vw;
      height: 10vw;
      padding: 0.1vw;
      border-radius: 5px;
      font-size: 3vw;
      color: black;
      margin-left: 5%;
    }

    .amount2 {
      margin: 0.3vw 1vw;
      width: 35%;
      font-size: 5.3vw;
      padding: 0.2vw 0;
      border: none;
      text-align: center;
      //box-shadow: 0 0 2px 1px rgba(0,0,0,0.2);
      box-shadow: none;
      border-radius: 5px;
    }

    .remover {
      background-color: rgb(217, 217, 230);
      /*margin-top: -4vw;*/
      height: 2vw;
      width: 2vw;
      margin-left: 0.8vw;
      font-size: 15px;
      border-radius: 0.3vw;
      color: white;
      cursor: pointer;
    }

    .change-amount-button {
      color: darkred;
      /*background-color: darkred;*/
      border-radius: 0.4vw;
      /*border-top: 0.2vw solid darkred;*/
      /*border-bottom: 0.2vw solid darkred;*/
      /*box-shadow: 0 0 0.1vw 0.1vw rgba(0,0,0,0.15);*/
      cursor: pointer;
      width: 20px;
      height: 20px;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  
`