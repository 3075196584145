import './deliveryData.css'
import * as React from "react";
import {useEffect, useState} from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";

const DeliveryData = (props) => {

    const deliveryData = props.deliveryData

    const deliveryType = deliveryData.deliveryType
    const province = deliveryData.province
    const city = deliveryData.city
    const street = deliveryData.street
    const number = deliveryData.number
    const postalCode = deliveryData.postalCode
    const aditionalNotes = deliveryData.notes

    const setDeliveryType = deliveryData.setDeliveryType
    const setProvince = deliveryData.setProvince
    const setCity = deliveryData.setCity
    const setStreet = deliveryData.setStreet
    const setNumber = deliveryData.setNumber
    const setPostalCode = deliveryData.setPostalCode
    const setAditionalNotes = deliveryData.setAditionalNotes

    const [jumboBranch, setJumboBranch] = useState('')
    const [carrefourBranch, setCarrefourBranch] = useState('')
    const [cotoBranch, setCotoBranch] = useState('')

    const handlePickupClick = (event) => {
        event.preventDefault();
        // const pickupData = {
        //     deliveryType: 'PICK_UP',
        //     province: province,
        //     city: city,
        //     street: '',
        //     number: '',
        //     postalCode: '',
        //     notes: '',
        // }
        // mySystem.setDeliveryLocation(token, pickupData)
        props.sendRequest()
        props.setAccordionOpen(false)
        props.setNextAccordionOpen(true)
        props.setDeliveryDone(true)
    };

    const handleDeliveryClick = (event) => {
        event.preventDefault();
        // const deliveryData = {
        //     deliveryType: 'HOME_DELIVERY',
        //     province: province,
        //     city: city,
        //     street: street,
        //     number: number,
        //     postalCode: postalCode,
        //     notes: aditionalNotes,
        // }
        // mySystem.setDeliveryLocation(token, deliveryData)
        props.sendRequest()
        props.setAccordionOpen(false)
        props.setNextAccordionOpen(true)
        props.setDeliveryDone(true)
    };

    const [selectedButton, setSelectedButton] = useState(1);

    const handleDoubleButtonClick = (buttonNumber) => {
        setSelectedButton(buttonNumber);
        setDeliveryType(buttonNumber === 1 ? 'PICKUP' : 'HOME_DELIVERY')
    };

    return(
        <div>
            <div className="button-container">
                <button
                    className={`custom-button-left ${selectedButton === 1 ? 'selected' : ''}`}
                    onClick={() => handleDoubleButtonClick(1)}
                >
                    Retiro por local
                </button>
                <button
                    className={`custom-button-right ${selectedButton === 2 ? 'selected' : ''}`}
                    onClick={() => handleDoubleButtonClick(2)}
                >
                    Envio a domicilio
                </button>
            </div>
            {selectedButton === 1 ? (
                <div className='delivery-container'>
                    <div className='delivery-title'>
                        <h2>Seleccione la sucursal (NO DISPONIBLE)</h2>
                    </div>
                    <form id="delivery" className="delivery-form" onSubmit={handlePickupClick}>
                        <div className='delivery-details'>
                            <div className='delivery-fields'>
                                <input type="text" placeholder="Provincia" value={province} onChange={(e) => setProvince(e.target.value)} required/>
                                <input type="text" placeholder="Ciudad" value={city} onChange={(e) => setCity(e.target.value)} required/>
                            </div>
                            <div className='delivery-fields' style={{ width: '70%', marginTop: '2.5vw' }}>
                                <div className='supermarket-name'>Jumbo</div>
                                <select value={jumboBranch} onChange={(e) => setJumboBranch(e.target.value)} disabled={true}>
                                    <option value="">Sucursal Jumbo</option>
                                    <option value="branch1">Jumbo Martinez</option>
                                    <option value="branch2">Jumbo CABA</option>
                                    <option value="branch3">Jumbo Pilar</option>
                                </select>
                            </div>
                            <div className='delivery-fields' style={{ width: '70%' }}>
                                <div className='supermarket-name'>Carrefour</div>
                                <select value={carrefourBranch} onChange={(e) => setCarrefourBranch(e.target.value)} disabled={true}>
                                    <option value="">Sucursal Carrefour</option>
                                    <option value="branch1">Carrefour Martinez</option>
                                    <option value="branch2">Carrefour CABA</option>
                                    <option value="branch3">Carrefour Pilar</option>
                                </select>
                            </div>
                            <div className='delivery-fields' style={{ width: '70%', marginBottom: '-0.5vw' }}>
                                <div className='supermarket-name'>Coto</div>
                                <select value={cotoBranch} onChange={(e) => setCotoBranch(e.target.value)} disabled={true}>
                                    <option value="">Sucursal Coto</option>
                                    <option value="branch1">Coto Martinez</option>
                                    <option value="branch2">Coto CABA</option>
                                    <option value="branch3">Coto Pilar</option>
                                </select>
                            </div>
                        </div>
                        <button type="submit" className='delivery-button' >
                            Continuar
                        </button>
                    </form>
                </div>
            ) : (
                <div className='delivery-container'>
                    <div className='delivery-title'>
                        <h2>Ingrese la dirección de entrega</h2>
                    </div>
                    <form id="delivery" className="delivery-form" onSubmit={handleDeliveryClick}>
                        <div className='delivery-details'>
                            <div className='delivery-fields'>
                                <input type="text" placeholder="Provincia" value={province} onChange={(e) => setProvince(e.target.value)} required />
                                <input type="text" placeholder="Ciudad" value={city} onChange={(e) => setCity(e.target.value)} required />
                            </div>
                            <div className='delivery-fields'>
                                <input type="text" placeholder="Calle" style={{ flex: 24.5 }} value={street} onChange={(e) => setStreet(e.target.value)} required />
                                <input type="text" placeholder="Numero" style={{ flex: 10 }} value={number} onChange={(e) => setNumber(e.target.value)} required />
                                <input type="text" placeholder="Código postal" style={{ flex: 10 }} value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required />
                            </div>
                            <div>
                                <input className='delivery-notes' type="text" placeholder="Notas adicionales" value={aditionalNotes} onChange={(e) => setAditionalNotes(e.target.value)} />
                            </div>
                        </div>
                        <button type="submit" className='delivery-button' >
                            Continuar
                        </button>
                    </form>
                </div>
                )
            }
        </div>
    )
}

export default DeliveryData