import * as React from 'react'
import './admin.css'
import {Link, useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useAuthProvider} from "../auth/auth";

export const AdminPage = () => {
    const [searchParams] = useSearchParams();
    const isOk = searchParams.get("ok")
    const navigate = useNavigate()
    const auth = useAuthProvider()

    const signOut = () => {
        auth.removeToken();
        navigate("/");
    }

    return(
        <div className="background-image">
            {isOk && <div className="alert-product alert-success" role="alert">Created</div>}
            <div className="admin-form">
                {/*<label>*/}
                {/*    <Link to="/registerSupermarket">*/}
                {/*        <button type="button" className="btn">Register Supermarket</button>*/}
                {/*    </Link>*/}
                {/*</label>*/}
                {/*<label>*/}
                {/*    <Link to="/removeSupermarket">*/}
                {/*        <button type="button" className="btn">Remove Supermarket</button>*/}
                {/*    </Link>*/}
                {/*</label>*/}
                <label>
                    <Link to="/manageOrders">
                        <button type="button" className="btn">Administrar pedidos</button>
                    </Link>
                </label>
                <label>
                    <Link to="/registerCategory">
                        <button type="button" className="btn">Register Category</button>
                    </Link>
                </label>
                <label>
                    <Link to="/removeCategory">
                        <button type="button" className="btn">Remove Category</button>
                    </Link>
                </label>
                {/*<label>*/}
                {/*    <Link to="/registerProduct">*/}
                {/*        <button type="button" className="btn">Register Product</button>*/}
                {/*    </Link>*/}
                {/*</label>*/}
                {/*<label>*/}
                {/*    <Link to="/registerDiscount">*/}
                {/*        <button type="button" className="btn">Register Discount</button>*/}
                {/*    </Link>*/}
                {/*</label>*/}
                <label>
                    <button className="btn" href="" onClick={signOut}> Sign Out</button>
                </label>
            </div>
        </div>
    )
}