import './pendingOrderProduct.css'
import {useEffect, useState} from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";

const PendingOrderProduct = ({ product }) => {

    const mySystem = useMySystem()
    const auth = useAuthProvider()
    const token = auth.getAdminToken()
    const [isLoading, setIsLoading] = useState(false);

    const [bought, setBought] = useState(false)

    const data = {
        productId: product.name,
        cartId: product.cartId,
    }

    const [amount, setAmount] = useState(0)

    useEffect(() => {
        mySystem.getAmountAdmin(token, data, isLoading, (amount) => setAmount(amount))
    }, [])

    useEffect(() => {
        mySystem.getIsBought(token, data, (bought) => setBought(!bought))
    }, [])

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }


    const handleClick = () => {
        window.open(product.link, '_blank')
    }

    const handleBought = () => {
        mySystem.setBoughtProduct(token, data)
        setBought(true)
    }

    return(
        <div className="product">
            <img src={product.image} alt="image"/>
            <div style={{ width: '70%' }}>
                <div className="name">
                    {product.name}
                </div>
                <div className="price">
                    Precio: {formatNumber(product.price)}
                </div>
            </div>
            <div className="amount">
                Cantidad: {amount}
            </div>
            {/*<div className="supermarket">*/}
            {/*    {product.supermarket}*/}
            {/*</div>*/}
            <button className="link-button" onClick={handleClick}>
                Comprar
            </button>
            <input
                className="checkbox"
                type="checkbox"
                checked={bought}
                onChange={handleBought}
            />
        </div>
    )
}

export default PendingOrderProduct;