import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useMySystem} from "../service/mySystem";
import './discountRegister.css'
import {useAuthProvider} from "../auth/auth";
import {logDOM} from "@testing-library/react";

export const DiscountRegisterPage = () => {

    const [errorMsg, setErrorMsg] = useState(undefined)
    const [supermarkets, setSupermarkets] = useState([]);
    const [supermarket, setSupermarket] = useState();
    const [daysOfWeek, setDaysOfWeek] = useState(["Lunes", "Martes", "Miercoles", "Jueves","Viernes", "Sabado","Domingo"]);
    const [dayOfWeek, setDayOfWeek] = useState()
    const [amountOfDiscount, setAmountOfDiscount] = useState();
    const navigate = useNavigate();
    const mySystem = useMySystem();
    const auth = useAuthProvider();
    const token = auth.getAdminToken();

    useEffect(() => {
        mySystem.listSupermarkets(token, (supermarkets) => setSupermarkets(supermarkets))
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();

        registerDiscount({
            supermarket: supermarket,
            dayOfWeek: dayOfWeek,
            amountOfDiscount: amountOfDiscount,
            id: supermarket+dayOfWeek+amountOfDiscount,
        })
    }
    
    const registerDiscount = (discount) => {
        mySystem.registerDiscount(
            discount,
            () => navigate("/admin?ok=true"),
            () => {
                setErrorMsg('Discount already exists!')
                setSupermarket('')
            }
        )
    }

    const supermarketChange = (event) => {
        setSupermarket(event.target.value)
    }

    const dayOfWeekChange = (event) => {
        setDayOfWeek(event.target.value)
    }

    const amountOfDiscountChange = (event) => {
        setAmountOfDiscount(event.target.value)
    }

    return(
        <div className="background-image">
            {errorMsg && <div className="alert-product alert-danger" role="alert">{errorMsg}</div>}
            <div className="registerDiscount-form">
                <h4>Register Discount</h4>
                <form id="registerDiscount" className="input-group-d" encType="multipart/form-data" method="POST"  onSubmit={handleSubmit}>
                    <label>
                        <select className="input-field-d" value={supermarket} onChange={supermarketChange}>
                            <option value="Supermercados">Supermercados</option>
                            {supermarkets.map(supermarket =>
                                <option className="input-field" value={supermarket.name} onChange={supermarketChange} required>{supermarket.name}</option>
                            )}
                        </select>
                    </label>
                    <label>
                        <select className="input-field-d" value={dayOfWeek} onChange={dayOfWeekChange}>
                            <option value="Día de la Semana">Día de la Semana</option>
                            {daysOfWeek.map(day =>
                                <option className="input-field" value={day} onChange={dayOfWeekChange} required>{day}</option>
                            )}
                        </select>
                    </label>
                    <label>
                        <input type="number" min={1} max={99} className="input-field-d" placeholder="Porcentaje de descuento" value={amountOfDiscount} onChange={amountOfDiscountChange} required/>
                    </label>
                    {/*<div className="porcentaje">%</div>*/}
                    <button type="submit" className="registerDiscount-btn" >Register Discount</button>
                </form>
            </div>
        </div>
    )
}

