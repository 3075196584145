import './App.css';
import {Route} from "react-router-dom";
import {Routes} from "react-router";
import {PublicPage} from "./pages/PublicPage";
import {LoginPage} from "./pages/LoginPage";
import {RegisterPage} from "./pages/RegisterPage";
import {AdminPage} from "./pages/AdminPage";
import {SupermarketRegisterPage} from "./pages/SupermarketRegisterPage";
import {RemoveSupermarketPage} from "./pages/RemoveSupermarketPage";
import {CategoryRegisterPage} from "./pages/CategoryRegisterPage";
import {RemoveCategoryPage} from "./pages/RemoveCategoryPage";
import {ProductRegisterPage} from "./pages/ProductRegisterPage";
import {DiscountRegisterPage} from "./pages/DiscountRegisterPage";
import {HomePage} from "./pages/HomePage";
import {RequireAuth} from "./components/RequireAuth";
import {RequireAdminAuth} from "./components/RequireAdminAuth";
import {ProductPage} from "./pages/ProductPage";
import {CartPage} from "./pages/CartPage";
import {useAuthProvider} from "./auth/auth";
import {useMySystem} from "./service/mySystem";
import React, {useEffect, useState} from "react";
import {CheckoutPage} from "./pages/CheckoutPage";
import {SuccessPage} from "./pages/SuccessPage";
import {FailurePage} from "./pages/FailurePage";
import PaymentDetailsPage from "./pages/PaymentDetailsPage";
import ManageOrdersPage from "./pages/ManageOrdersPage";
import MyOrdersPage from "./pages/MyOrdersPage";


function App() {
    const auth = useAuthProvider()
    const token = auth.getToken()
    const mySystem = useMySystem()
    // const [email,setEmail] = useState('')
    const [categories, setCategories] = useState([])

    useEffect(() => {
        // mySystem.userEmail(token, (email) => setEmail(email));
        mySystem.listCategories(token, (categories) => setCategories(categories))
    }, [])

  return (
      <Routes>
          {/*<Route path="/" element={<PublicPage/>}/>*/}
          <Route path="/" element={<HomePage/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/register" element={<RegisterPage/>}/>
          <Route
              path="/admin"
              element={
                  <RequireAdminAuth>
                      <AdminPage/>
                  </RequireAdminAuth>
              }
          />
          <Route
              path="/manageOrders"
              element={
                  <RequireAdminAuth>
                      <ManageOrdersPage/>
                  </RequireAdminAuth>
              }
          />
          <Route
              path="/registerSupermarket"
              element={
                  <RequireAdminAuth>
                      <SupermarketRegisterPage/>
                  </RequireAdminAuth>
              }
          />
          <Route
              path="/removeSupermarket"
              element={
                  <RequireAdminAuth>
                      <RemoveSupermarketPage/>
                  </RequireAdminAuth>
              }
          />
          <Route
              path="/registerCategory"
              element={
                  <RequireAdminAuth>
                      <CategoryRegisterPage/>
                  </RequireAdminAuth>
              }
          />
          <Route
              path="/removeCategory"
              element={
                  <RequireAdminAuth>
                      <RemoveCategoryPage/>
                  </RequireAdminAuth>
              }
          />
          <Route
              path="/registerProduct"
              element={
                  <RequireAdminAuth>
                      <ProductRegisterPage/>
                  </RequireAdminAuth>
              }
          />
          <Route
              path="/registerDiscount"
              element={
                  <RequireAdminAuth>
                      <DiscountRegisterPage/>
                  </RequireAdminAuth>
              }
          />
          <Route
              path="/home"
              element={
                  // <RequireAuth>
                    <HomePage/>
                  // </RequireAuth>
              }
          />
          {categories.map(category =>
              <Route
                  path={"/product/"+category.name}
                  element={
                      // <RequireAuth>
                          <ProductPage/>
                      // </RequireAuth>
                  }
              />
          )}
          <Route
              path={"/carrito"}
              element={
                  // <RequireAuth>
                      <CartPage/>
                  // </RequireAuth>
              }
          />
          <Route
              path={"/misPedidos"}
              element={
                  <RequireAuth>
                      <MyOrdersPage/>
                  </RequireAuth>
              }
          />
          <Route
              path={"/payment"}
              element={
                  <RequireAuth>
                      <PaymentDetailsPage/>
                  </RequireAuth>
              }
          />
          <Route
              path={"/checkout"}
              element={
                  <RequireAuth>
                      <CheckoutPage/>
                  </RequireAuth>
              }
          />
          <Route
              path={"/success"}
              element={
                  <RequireAuth>
                      <SuccessPage/>
                  </RequireAuth>
              }
          />
          <Route
              path={"/failure"}
              element={
                  <RequireAuth>
                      <FailurePage/>
                  </RequireAuth>
              }
          />
      </Routes>
  );
}

export default App;
