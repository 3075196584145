import {Navigate, useLocation} from "react-router";
import {useAuthProvider} from "../auth/auth";

export function RequireAdminAuth({children}) {
    const auth = useAuthProvider()
    const adminToken = auth.getAdminToken();
    const location = useLocation();

    if (!adminToken) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return children;
}