import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useMySystem} from "../service/mySystem";
import './registerProduct.css'
import {useAuthProvider} from "../auth/auth";

export const ProductRegisterPage = () => {
    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [jumboPrice, setJumboPrice] = useState('')
    const [carrefourPrice, setCarrefourPrice] = useState('')
    const [veaPrice, setVeaPrice] = useState('')
    const [image, setImage] = useState('')
    const [errorMsg, setErrorMsg] = useState(undefined)
    const navigate = useNavigate();
    const mySystem = useMySystem();
    const auth = useAuthProvider()
    const token = auth.getAdminToken();
    const [categories, setCategories] = useState([])

    useEffect(() => {
        mySystem.listCategories(token, (categories) => setCategories(categories))
        categories.sort();
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();

        registerProduct({
            name: name,
            category: category.toLowerCase(),
            jumboPrice: jumboPrice,
            carrefourPrice: carrefourPrice,
            veaPrice: veaPrice,
            image: image,
        })
    }
    
    const registerProduct = (product) => {
        mySystem.registerProduct(
            product,
            () => navigate("/admin?ok=true"),
            () => {
                setErrorMsg('Product already exists!')
                setName('')
                setCategory('')
                setJumboPrice('')
                setCarrefourPrice('')
                setVeaPrice('')
                setImage('')
            }
        )
    }

    const nameChange = (event) => {
        setName(event.target.value)
    }

    const jumboPriceChange = (event) => {
        setJumboPrice(event.target.value)
    }

    const carrefourPriceChange = (event) => {
        setCarrefourPrice(event.target.value)
    }

    const veaPriceChange = (event) => {
        setVeaPrice(event.target.value)
    }

    const imageChange = (event) => {
        let  file = event.target.files[0];
        let reader = new FileReader();

        function _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        }

        reader.readAsArrayBuffer(file)

        reader.onload = function(evt) {
            var base64 = _arrayBufferToBase64(evt.target.result)
            console.log(base64)
            setImage(base64)
        }
    }


    const categoryChange = (event) => {
        setCategory(event.target.value)
    }

    return(
        <div className="background-image">
            {errorMsg && <div className="alert-product alert-danger" role="alert">{errorMsg}</div>}
            <div className="registerProduct-form">
                <h4>Complete the following fields</h4>
                <form id="registerProduct" className="input-group-p" encType="multipart/form-data" method="POST"  onSubmit={handleSubmit}>
                        <input type="text" className="input-field-p" placeholder="Name" value={name} onChange={nameChange} required/>
                        <select className="input-field-p1" value={category} onChange={categoryChange}>
                            <option value="Categorias">Categorías</option>
                            {categories.map(category =>
                                <option className="input-field-p" value={category.name} onChange={categoryChange} required>{category.name}</option>
                            )}
                        </select>
                        <input type="number" className="input-field-p" placeholder="Jumbo Price" value={jumboPrice} onChange={jumboPriceChange} required/>
                        <input type="number" className="input-field-p" placeholder="Carrefour Price" value={carrefourPrice} onChange={carrefourPriceChange} required/>
                        <input type="number" className="input-field-p" placeholder="Vea Price" value={veaPrice} onChange={veaPriceChange} required/>
                        <input type="file" className="input-field-p"  onChange={imageChange} />
                        <button type="submit" className="registerProduct-btn" >Register Product</button>
                </form>
            </div>
        </div>
    )
}

