import Navbar from "../components/Navbar";
import {useAuthProvider} from "../auth/auth";
import {useEffect, useState} from "react";
import {useMySystem} from "../service/mySystem";
import PendingOrder from "../components/PendingOrder";
import * as React from "react";
import GoBackButton from "../components/GoBackButton";
import {FaArrowLeft} from "react-icons/fa";
import {useNavigate} from "react-router";


const MyOrdersPage = () => {

    const auth = useAuthProvider()
    const token = auth.getToken()
    const mySystem = useMySystem()
    const navigate = useNavigate()

    const [myOrders, setMyOrders] = useState([])

    const [selectedOrder, setSelectedOrder] = useState()
    const [selectedId, setSelectedId] = useState()

    const [showModal, setShowModal] = useState(false)

    useEffect(() =>{
        mySystem.getMyOrders(token, (orders) => setMyOrders(orders))
    }, [])

    const getEmail = (str) => {
        const parts = str.split(' ');
        return parts[0];
    }

    const getDate = (str) => {
        const parts = str.split(' ');
        return parts[1];
    }

    const getHour = (str) => {
        const parts = str.split(' ');
        return parts[2];
    }

    const handleClick = (order, id) => {
        setSelectedOrder(order)
        setSelectedId(id)
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }

    return(
        <div>
            <Navbar />
            <div style={{ height: '5vw' }}></div>
            <div style={{ display: 'flex', width: '98%'}}>
                <button style={{
                    margin: '3vw 3% 0 10%',
                    flex: '5',
                    width:'5vw',
                    backgroundColor: 'rgb(245, 245, 253)' ,
                    height: '2.6vw',
                    boxShadow: '0 0 0.15vw 0.07vw rgba(0, 0, 0, 0.2)',
                    borderRadius: '0.5vw',
                    color: 'darkred',
                    fontSize: '1.3vw',
                    position: 'absolute'
                }}
                        onClick={() => navigate(-1)}
                >
                    <FaArrowLeft />
                </button>
                <div className="manage-orders-page">
                    <div className="pending-orders-container">
                        <h2>Pedidos pendientes</h2>
                        {
                            myOrders.filter(order => order.state === "ordered").map((order) => {
                                return (
                                    <div className="pending-order">
                                        <div className="email">
                                            {getEmail(order.cartId)}
                                        </div>
                                        <div className="date">
                                            <div>
                                                {getDate(order.cartId)}
                                            </div>
                                            <div>
                                                {getHour(order.cartId)}
                                            </div>
                                        </div>
                                        <div className="order-price">
                                            {formatNumber(order.cheapestTotal)}
                                        </div>
                                        <button className="see-more" onClick={() => handleClick(order, order.cartId)}>Ver</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="pending-orders-container">
                        <h2>Pedidos completados</h2>
                        {
                            myOrders.filter(order => order.state === "bought").map((order) => {
                                return (
                                    <div className="pending-order">
                                        <div className="email">
                                            {getEmail(order.cartId)}
                                        </div>
                                        <div className="date">
                                            <div>
                                                {getDate(order.cartId)}
                                            </div>
                                            <div>
                                                {getHour(order.cartId)}
                                            </div>
                                        </div>
                                        <div className="order-price">
                                            {formatNumber(order.cheapestTotal)}
                                        </div>
                                        <button className="see-more" onClick={() => handleClick(order, order.cartId)}>Ver</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="pending-orders-container">
                        <h2>Pedidos enviados</h2>
                        {
                            myOrders.filter(order => order.state === "delivered").map((order) => {
                                return (
                                    <div className="pending-order">
                                        <div className="email">
                                            {getEmail(order.cartId)}
                                        </div>
                                        <div className="date">
                                            <div>
                                                {getDate(order.cartId)}
                                            </div>
                                            <div>
                                                {getHour(order.cartId)}
                                            </div>
                                        </div>
                                        <div className="order-price">
                                            {formatNumber(order.cheapestTotal)}
                                        </div>
                                        <button className="see-more" onClick={() => handleClick(order, order.cartId)}>Ver</button>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        showModal &&
                        <PendingOrder order={selectedOrder} closeModal={closeModal} cartId={selectedId} email={getEmail(selectedId)}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default MyOrdersPage