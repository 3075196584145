import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useMySystem} from "../service/mySystem";
import './categoryRegister.css'
import {useAuthProvider} from "../auth/auth";

export const CategoryRegisterPage = () => {

    const [errorMsg, setErrorMsg] = useState(undefined)
    const [name, setName] = useState('');
    const [fatherCategory, setFatherCategory] = useState('');
    const [fatherCategories, setFatherCategories] = useState([]);
    const [image, setImage] = useState();
    const navigate = useNavigate();
    const mySystem = useMySystem();
    const auth = useAuthProvider();
    const token = auth.getAdminToken();

    useEffect(() => {
        mySystem.listFatherCategories(token, (fatherCategories) => setFatherCategories(fatherCategories))
        fatherCategories.sort();
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        saveImage();
        registerCategory({
            name: name,
            fatherCategory: fatherCategory,
            image: image,
        })
    }

    const saveImage = () => {

    }

    const registerCategory = (category) => {
        mySystem.registerCategory(
            category,
            () => navigate("/admin?ok=true"),
            () => {
                setErrorMsg('Category already exists!')
                setName('')
                setFatherCategory('')
                setImage('')
            }
        )
    }

    const nameChange = (event) => {
        setName(event.target.value)
    }

    const fatherCategoryChange = (event) => {
        setFatherCategory(event.target.value)
    }

    const imageChange = (event) => {
        let  file = event.target.files[0];
        let reader = new FileReader();

        function _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        }

        reader.readAsArrayBuffer(file)

        reader.onload = function(evt) {
            var base64 = _arrayBufferToBase64(evt.target.result)
            console.log(base64)
            setImage(base64)
        }
    }

    return(
        <div className="background-image">
            {errorMsg && <div className="alert-product alert-danger" role="alert">{errorMsg}</div>}
            <div className="registerCategory-form">
                <h4>Register Category</h4>
                <form id="registerCategory" className="input-group-c" encType="multipart/form-data" method="POST"  onSubmit={handleSubmit}>
                        <input type="text" className="input-field-c" placeholder="Name" value={name} onChange={nameChange} required/>
                        <select className="input-field-c" value={fatherCategory} onChange={fatherCategoryChange}>
                            <option value="Categorias">Categorías Padre</option>
                            {fatherCategories.map(fatherCategory =>
                                <option className="input-field-c" value={fatherCategory.name} onChange={fatherCategoryChange} required>{fatherCategory.name}</option>
                            )}
                        </select>
                    <input type="file" className="input-field-c"  onChange={imageChange} />
                    <button type="submit" className="registerCategory-btn" >Register Category</button>
                </form>
            </div>
        </div>
    )
}

