import * as React from 'react'
import "./cart.css"
import Navbar from "../components/Navbar";
import ShoppingCart from "../components/ShoppingCart";
import {Pedido} from "../components/Pedido";
import {useEffect, useState} from "react";
import {useMySystem} from "../service/mySystem";
import {useAuthProvider} from "../auth/auth";
import GoBackButton from "../components/GoBackButton";
import Modal from "../components/Modal";
import logoJumbo from "./images/Jumbo 2.png";
import logoCarrefour from "./images/Carrefour.png";
import logoCoto from "./images/Coto 2.png";
import {AiFillInfoCircle} from "react-icons/ai";

export const CartPage = () => {

    const [cartItems, setCartItems] = useState([]);
    const [jumboPrice, setJumboPrice] = useState()
    const [carrefourPrice, setCarrefourPrice] = useState()
    const [cotoPrice, setCotoPrice] = useState()
    const [smarketPrice, setSmarketPrice] = useState(0)
    const [expensivePrice, setExpensivePrice] = useState(0)
    const [supermarkets, setSupermarkets] = useState([]);

    const mySystem = useMySystem()
    const auth = useAuthProvider()
    const token = auth.getToken()
    const [aux, setAux] = useState(false)

    const [openModal, setOpenModal] = useState(false);
    const [openModalBuyCart, setOpenModalBuyCart] = useState(false);
    const [showInfoSupers, setShowInfoSupers] = useState(false);

    const prices = [
        {
            name: "jumboPrice",
            price: jumboPrice,
            logo: logoJumbo
        },
        {
            name: "carrefourPrice",
            price: carrefourPrice,
            logo: logoCarrefour
        },
        {
            name: "veaPrice",
            price: cotoPrice,
            logo: logoCoto
        },
    ]
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        mySystem.showCartItems(setIsLoading, token, (cartItems) => setCartItems(cartItems));

        mySystem.getSmarketPrice(token, (smarketPrice) => setSmarketPrice(smarketPrice))
        mySystem.getExpensivePrice(token, (expensivePrice) => setExpensivePrice(expensivePrice))
        mySystem.getJumboPrice(token, (jumboPrice) => setJumboPrice(jumboPrice))
        mySystem.getCarrefourPrice(token, (carrefourPrice) => setCarrefourPrice(carrefourPrice))
        mySystem.getVeaPrice(token, (cotoPrice) => setCotoPrice(cotoPrice))
    }, [])

    // useEffect(() => {
    //     // mySystem.getSmarketPrice(token, (smarketPrice) => setSmarketPrice(smarketPrice))
    //     // mySystem.getExpensivePrice(token, (expensivePrice) => setExpensivePrice(expensivePrice))
    //     // mySystem.getJumboPrice(token, (jumboPrice) => setJumboPrice(jumboPrice))
    //     // mySystem.getCarrefourPrice(token, (carrefourPrice) => setCarrefourPrice(carrefourPrice))
    //     // mySystem.getVeaPrice(token, (cotoPrice) => setCotoPrice(cotoPrice))
    // }, [aux])

    const formatNumber = (number) => {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(number);
    }

    function emptyCart() {
        mySystem.removeItems(token);
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleOpenModalBuyCart = () => {
        setOpenModalBuyCart(true);
    }

    const handleCloseModalBuyCart = () => {
        setOpenModalBuyCart(false);
    }

    const handleInfoSupersIn = () => {
        setShowInfoSupers(true);
    };

    const handleInfoSupersOut = () => {
        setShowInfoSupers(false);
    };

    function openLinksJumbo() {
        cartItems.forEach((item) => {
            if (item.product.lowestPrice === item.product.jumboPrice) {
                window.open(item.product.jumboLink, '_blank');
            }
        })
    }

    function openLinksCarrefour() {
        cartItems.forEach((item) => {
            if (item.product.lowestPrice === item.product.carrefourPrice) {
                window.open(item.product.carrefourLink, '_blank');
            }
        })
    }

    function openLinksCoto() {
        cartItems.forEach((item) => {
            if (item.product.lowestPrice === item.product.cotoPrice) {
                window.open(item.product.cotoLink, '_blank');
            }
        })
    }

    return(
        <div className="cart-container">
            <Navbar aux={aux}/>
            <div className="cart-header">
                <div className="go-back">
                    <GoBackButton />
                </div>
                <div className="title-container"  style={{ flex: '95' }} >
                    <h2 className="titulo-carrito">Carrito de compras</h2>
                </div>
            </div>
            <div className="cart-container-2" >
                <ShoppingCart
                    aux={aux}
                    setAux={setAux}
                    isLoading={isLoading}
                    cartItems={cartItems}

                    setSmarketPrice={setSmarketPrice}
                    setExpensivePrice={setExpensivePrice}
                    setJumboPrice={setJumboPrice}
                    setCarrefourPrice={setCarrefourPrice}
                    setCotoPrice={setCotoPrice}
                />
                <Pedido
                    prices={prices}
                    smarketPrice={smarketPrice}
                    expensivePrice={expensivePrice}
                    aux={aux}
                    setAux={setAux}
                    handleOpenModal={handleOpenModal}
                    handleOpenModalBuyCart={handleOpenModalBuyCart}

                    setSmarketPrice={setSmarketPrice}
                    setExpensivePrice={setExpensivePrice}
                    setJumboPrice={setJumboPrice}
                    setCarrefourPrice={setCarrefourPrice}
                    setCotoPrice={setCotoPrice}

                    cartItems={cartItems}
                    setCartItems={setCartItems}
                />
            </div>
            <div style={{ height: '2vw' }} />
            <Modal isOpen={openModal} onClose={handleCloseModal}>
                <div className="info-modal">
                    {prices.map(price =>
                        <div className="modal-price-container" >
                            <img src={price.logo} alt="img-super"/>
                            <div className="super-price">
                                Total:
                                <div className="super-number">
                                    {price.price === -1 ?
                                        <div>{formatNumber(0)}</div>
                                        :
                                        <div>{formatNumber(price.price)}</div>
                                    }
                                </div>
                            </div>
                            {/*<AiFillInfoCircle*/}
                            {/*    color={'rgb(200, 200, 210)'}*/}
                            {/*    onMouseOver={handleInfoSupersIn}*/}
                            {/*    onMouseOut={handleInfoSupersOut}*/}
                            {/*    style={{ marginLeft: 'auto', marginRight: '5%' }}*/}
                            {/*    size={'1.5vw'}*/}
                            {/*/>*/}
                        </div>
                    )}
                    <div className="info-supers">
                        {/*Indica cuanto vale la compra en cada supermercado. No se pueden utilizar para comparar acertadamente los precios,*/}
                        {/*ya que si no hay stock de un producto en un supermercado, el precio de dicho producto no será tomado en cuenta al calcular*/}
                        {/*el costo total del pedido en ese supermercado.*/}

                        Los recuadros muestran el precio total del pedido en cada supermercado,
                        considerando únicamente los productos cuyo precio más bajo corresponde al supermercado en cuestión.
                        El precio "Smarket" es el resultado de la sumatoria de estos.
                    </div>
                </div>
            </Modal>
            <Modal isOpen={openModalBuyCart} onClose={handleCloseModalBuyCart}>
                <div className="buy-modal">
                    <p className="buying-info">
                        Al hacer click en los siguientes botones se abrirán las paginas de los productos
                        en los correspondientes supermercados.
                    </p>
                    <p className="buying-info-important">
                        IMPORTANTE: Si es su primera vez utilizando la aplicación, se abrirá un solo link. Debe cerrarlo
                        y volver a esta pestaña, en la cual encontrará un mensaje del navegador indicando que se han
                        bloqueado ventanas emergentes. Debe seleccionar en "Permitir siempre" y luego volver a hacer
                        click en
                        los botones para que se abran los links correspondientes.
                    </p>
                    <div className="links-modal">
                        <button onClick={openLinksJumbo}>
                            Abrir links de Jumbo
                        </button>
                        <button onClick={openLinksCarrefour}>
                            Abrir links de Carrefour
                        </button>
                        <button onClick={openLinksCoto}>
                            Abrir links de Coto
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}