import React, {useState} from "react";
import {useNavigate} from "react-router";
import {useAuthProvider} from "../auth/auth";
import styled from "styled-components";
import navbarBanner from "../pages/images/backgrounds/navbar/Navbar background 18.png";
import logo from "../pages/images/Logo Red 1.png";
import BurguerButton from "./BurgerButton";
import {BsCart2} from "react-icons/bs";
import ProgressBarButton from "./ProgressBarButton";

function Navbar(props){

    const navigate = useNavigate()
    const auth = useAuthProvider()
    const signOut = () => {
        auth.removeToken();
        navigate("/login");
    }
    const [clicked, setClicked] = useState(false)

    const handleClick = () => {
        setClicked(!clicked)
    }

    return (
        <StyledNavbar>
            <img src={navbarBanner} alt="" className="navbar-banner"/>
            <button className="navbar-button" onClick={() => navigate("/home")}>
                <img src={logo} alt="" className="navbar-logo"/>
            </button>
            <div className="progressbar-button">
                <ProgressBarButton aux={props.aux}/>
            </div>
            <div className='burguer'>
                <BurguerButton clicked={clicked} handleClick={handleClick} />
            </div>
            <BgDiv className={`initial ${clicked ? ' active' : ''}`}></BgDiv>
            <ul className={`nav-menu ${clicked ? 'active' : ''}`}>
                <a className="only-mobile" href="" onClick={() => navigate('/home')}>Categorías</a>
                <a className="only-mobile" href="" onClick={() => navigate('/carrito')}>Carrito</a>
                {/*<a href="" onClick={() => navigate('/misPedidos')}>Mis Pedidos</a>*/}
                <a href="" onClick={signOut}> Cerrar Sesión</a>
            </ul>
            <div className="cart-btn-mobile" onClick={() => navigate('/carrito')} >
                <BsCart2 className="cart-icon" />
            </div>
        </StyledNavbar>
    )
}

export default Navbar



const StyledNavbar = styled.nav`
  width: 100vw;
  height: 4vw;
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: darkred;
  box-shadow: 0 0 0.4vw 0.1vw rgba(0, 0, 0, 0.3);

  .navbar-banner {
    position: absolute;
    width: 100vw;
  }

  .navbar-logo {
    width: 10vw;
  }

  .navbar-button {
    border: none;
    position: absolute;
    background-color: transparent;
    margin-left: 5%;
  }

  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 2vw;
    list-style: none;
    text-align: center;
    width: 70vw;
    margin-left: auto;
    margin-right: 3%;
    justify-content: end;
    /*margin-right: 2rem;*/
    font-size: large;
    z-index: 100;

    a {
      color: white;
      text-decoration: none;
      padding: 0.6vw 0.9vw;
      font-size: 1.1vw;
      font-weight: 500;
    }

    a:hover {
      background-color: #f00000;
      border-radius: 0.5vw;
    }

    .only-mobile {
      display: none;
    }

  }
  
  .progressbar-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 5.5vw 0 0 30vw;
  }

  .burguer {
    display: none;
  }

  .cart-icon {
    display: none;
  }
  
  .cart-btn-mobile {
    display: none;
  }

  @media (max-device-width: 700px) {
    width: 100vw;
    height: 17vw;
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: darkred;
    box-shadow: 0 0 0.4vw 0.1vw rgba(0, 0, 0, 0.3);

    .burguer {
      display: block;
      opacity: 1;
    }

    .navbar-button {
      position: absolute;
      background-color: transparent;
      margin-left: 15%;
    }

    .navbar-logo {
      width: auto;
      height: 10vw;
    }

    .navbar-banner {
      position: absolute;
      margin-left: -7%;
      width: 360vw;
      height: 17vw;
      background-size: cover;
    }

    .cart-btn-mobile {
      display: block;
      position: absolute;
      margin-left: 88%;
      color: white;
      height: 8vw;
      width: 8vw;
    }

    .cart-icon {
      position: absolute;
      display: block;
      color: white;
      height: 8vw;
      width: 8vw;
    }

    .nav-menu {
      display: none;
    }

    .nav-menu.active {
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;
      margin-top: 150%;
      position: absolute;

      a {
        color: darkred;
        font-size: 6vw;
        width: 50%;
      }

      .only-mobile {
        display: block;
      }

      transition: all .4s ease;
      opacity: 1;
    }

    .progressbar-button {
      display: none;
    }
  }

`

const BgDiv = styled.div`
  background-color: #ffffff;
  position: absolute;
  z-index: -1;
  transition: all .2s ease;
  //border-radius: 0 0 80% 0;
  opacity: 0;

  &.active {
    top: 17vw;
    width: 100vw;
    height: 100vh;
    opacity: 1;
  }
`
